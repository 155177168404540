import { isMobile } from 'mobile-device-detect';
import { Spine } from 'pixi-spine';
import { Loader, Sprite, Texture } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import variables from '../../assets/styles/export.module.scss';
import { ISongs } from '../../config';
import { EventTypes, GameMode, ModalOpeningTypes } from '../../global.d';
import {
  setCurrentBonus,
  setCurrentBonusId,
  setIsAutoSpins,
  setIsLeftHandMode,
  setIsModalOpeningInProgress,
  setIsModalVisible,
} from '../../gql/cache';
import i18n from '../../i18next';
import { Logic } from '../../logic';
import { ResourceTypes } from '../../resources.d';
import { TextField } from '../components/TextField';
import { ViewContainer } from '../components/ViewContainer';
import {
  FEATURE_BTN_CONTAINER_POSITION_X,
  FEATURE_BTN_HEIGHT,
  GAME_CONTAINER_HEIGHT,
  GAME_CONTAINER_WIDTH,
  PopupTypes,
  buyFeatureClickTextStyle,
  buyFeatureDisableTextStyle,
  buyFeatureHoverTextStyle,
  buyFeatureTextStyle,
  eventManager,
} from '../config';
import { PopupController } from '../popups/PopupController';

class BuyFeatureBtn extends ViewContainer {
  private btn: Sprite;

  private btnAnimated!: Spine;

  private text: TextField;

  private btnAnimatedText: TextField;

  isDisabled: boolean;

  resizedWidth: number;

  resizedHeight: number;

  isLeftHandMode: boolean;

  constructor() {
    super();
    this.x = -150;
    this.y = 300;
    this.isDisabled = false;
    this.text = this.initBuyFeatureText();
    this.btnAnimatedText = this.initBuyFeatureText();
    this.btn = this.initBuyFeatureBtn();
    this.initBuyFeatureBtnAnimated();
    this.resizedWidth = 0;
    this.resizedHeight = 0;
    this.isLeftHandMode = setIsLeftHandMode();
    this.addChild(this.btn);

    this.interactive = true;
    this.buttonMode = true;
    this.on('click', () => {
      this.onClick();
    });
    this.on('touchstart', () => {
      this.onClick();
    });
    this.addListener('mouseover', () => {
      this.btn.visible = true;
      this.btnAnimated.visible = false;
    });
    this.addListener('mouseout', () => {
      if (!this.isDisabled) {
        this.btn.visible = false;
        this.btnAnimated.visible = true;
      }
    });

    this.handleDisable(!!setCurrentBonusId());

    eventManager.on(EventTypes.DISABLE_BUY_FEATURE_BTN, (disable: boolean) => {
      this.handleDisable(disable);
    });
    eventManager.on(EventTypes.SET_IS_LEFT_HAND_MODE, (isLhm: boolean) => {
      this.isLeftHandMode = isLhm;
      this.handlePosition();
    });
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    switch (settings.mode) {
      case GameMode.BASE_GAME:
        this.visible = true;
        break;
      case GameMode.FREE_PACKS:
        this.visible = false;
        break;
      case GameMode.MASTER_PACKS:
        this.visible = false;
        break;
      default:
        this.visible = true;
        break;
    }
  }

  private initBuyFeatureBtnAnimated(): void {
    const coin = new Spine(Loader.shared.resources['buyFeatureCoin']!.spineData!);
    coin.state.setAnimation(0, 'coin_button', true);
    coin.addChild(this.btnAnimatedText.getText());
    this.addChild(coin);
    this.btnAnimated = coin;
  }

  private initBuyFeatureBtn() {
    const btn = new Sprite(Texture.from(ResourceTypes.buyFeature));
    btn.anchor.set(0.5);
    btn.interactive = true;
    btn.buttonMode = true;
    btn.addChild(this.text.getText());
    btn.on('click', () => this.onClick());
    btn.on('touchstart', () => this.onClick());
    btn.visible = false;

    btn.addListener('mouseover', (_e) => {
      if (!this.isDisabled) {
        AudioApi.play({ type: ISongs.SFX_UI_Hover });
        this.btn.texture = Texture.from(ResourceTypes.buyFeatureHover);

        this.text.text.style = buyFeatureHoverTextStyle;
      }
    });
    btn.addListener('mouseout', () => {
      if (!this.isDisabled) {
        this.btn.texture = Texture.from(ResourceTypes.buyFeature);

        this.text.text.style = buyFeatureTextStyle;
      }
    });
    btn.addListener('mousedown', () => {
      if (!this.isDisabled) {
        this.btn.texture = Texture.from(ResourceTypes.buyFeaturePressed);

        this.text.text.style = buyFeatureClickTextStyle;
      }
    });
    btn.addListener('mouseup', () => {
      if (!this.isDisabled) {
        this.btn.texture = Texture.from(ResourceTypes.buyFeature);

        this.text.text.style = buyFeatureTextStyle;
      }
    });
    return btn;
  }

  private initBuyFeatureText(): TextField {
    const text = new TextField(i18n.t<string>('buyFeatureBtn'), 150, 300, buyFeatureTextStyle);
    text.text.anchor.set(0.5, 0.5);
    text.text.position.set(this.width / 2, 110);

    return text;
  }

  private onClick(): void {
    if (
      !this.isDisabled &&
      setIsModalOpeningInProgress() === ModalOpeningTypes.NONE &&
      !setIsAutoSpins() &&
      !setIsModalVisible()
    ) {
      this.btn.visible = true;
      this.btnAnimated.visible = false;
      setIsModalOpeningInProgress(ModalOpeningTypes.BUY_FEATURE_POPUP);
      AudioApi.play({ type: ISongs.SFX_UI_MenuOpen });
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);

      PopupController.the.openPopup(PopupTypes.BUY_FEATURE);

      setTimeout(() => setIsModalOpeningInProgress(ModalOpeningTypes.NONE), 100);
    }
  }

  private handleDisable(disable: boolean): void {
    if (this.isAutoSpinInProgress || this.isFreeRoundBonus) return;

    this.isDisabled = disable;
    this.buttonMode = !this.isDisabled;
    if (disable) {
      this.btn.texture = Texture.from(ResourceTypes.buyFeatureDisable);
      this.btn.buttonMode = false;
      this.text.text.style = buyFeatureDisableTextStyle;
      this.text.text.alpha = 0.5;
      this.btn.visible = true;
      this.btnAnimated.visible = false;
    } else {
      this.btn.texture = Texture.from(ResourceTypes.buyFeature);
      this.btn.buttonMode = true;
      this.text.text.style = buyFeatureTextStyle;
      this.text.text.alpha = 1;
      this.btn.visible = false;
      this.btnAnimated.visible = true;
    }
  }

  private get isAutoSpinInProgress(): boolean {
    return this.isDisabled && setIsAutoSpins();
  }

  private get isFreeRoundBonus(): boolean {
    return this.isDisabled && Logic.the.controller.gameMode === GameMode.FREE_ROUND_BONUS && setCurrentBonus().isActive;
  }

  protected override resize(width: number, height: number): void {
    this.resizedWidth = width;
    this.resizedHeight = height;
    this.handlePosition();
  }

  private handlePosition(): void {
    if (this.resizedWidth < this.resizedHeight && isMobile) {
      this.scale.set(1.3);
      this.x = this.isLeftHandMode ? GAME_CONTAINER_WIDTH : 120;
      this.y = GAME_CONTAINER_HEIGHT + FEATURE_BTN_HEIGHT + 150;
      return;
    }
    this.scale.set(1);
    if (
      this.resizedWidth >= parseInt(variables['breakpointTabletLandscapeMd'] as string, 10) &&
      this.resizedHeight <= parseInt(variables['breakpointTabletLandscapeXs'] as string, 10) &&
      isMobile
    ) {
      this.x = this.isLeftHandMode ? GAME_CONTAINER_WIDTH : FEATURE_BTN_CONTAINER_POSITION_X / 1.2;
    } else {
      this.x = this.isLeftHandMode && isMobile ? GAME_CONTAINER_WIDTH + 125 : -135;
    }
    this.y = this.isLeftHandMode ? 500 : 520;
  }
}

export default BuyFeatureBtn;
