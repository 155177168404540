import i18n from 'i18next';
import { Container, Filter, ITextStyle, Sprite, Text, Texture } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { CardAbilitiesPattern, ISongs } from '../../../config';
import { EventTypes } from '../../../global.d';
import { setBonusPackAutoPlay, setIsAutoSpins, setIsStopOnFeatureWin, setIsTurboSpin } from '../../../gql/cache';
import type Animation from '../../animations/animation';
import { TextField } from '../../components/TextField';
import type { ViewContainer } from '../../components/ViewContainer';
import { eventManager } from '../../config';
import type { RewardsPopupProps } from '../../d';
import { PopupController } from '../PopupController';
import { Popup } from '../popup';

import rewardsPopupBackgroundAnimated from './rewardsPopupBackgroundAnimated';
import {
  freeText1Style,
  freeText2Style,
  freeTextValueStyle,
  masterText1Style,
  masterText2Style,
  masterTextValueStyle,
  multiplierText1Style,
  multiplierTextValueStyle,
  rarityText1Style,
  rarityText2Style,
  textPress,
} from './textStyles';

export class RewardsPopup extends Popup {
  private props: RewardsPopupProps;

  protected popupBackground!: rewardsPopupBackgroundAnimated;

  protected pressToContinueText!: TextField;

  protected textContainer!: ViewContainer;

  public fadeOutAnimation!: Animation;

  public fadeInAnimation!: Animation;

  public backgroundFadeInAnimation!: Animation;

  public backgroundFadeOutAnimation!: Animation;

  protected autoSkipDelay: ReturnType<typeof setTimeout> | undefined;

  public get rewardsProps(): RewardsPopupProps {
    return this.props;
  }

  constructor() {
    super();
    this.interactive = true;
    this.visible = false;
    this.props = { pattern: CardAbilitiesPattern.Nup, value: 0, inId: 0, outId: 0 };
    this.x = 780;
    this.y = 570;
    this.pressToContinueText = new TextField(i18n.t('pressToContinue'), 500, 100, textPress);
    this.initBackground();
    this.init();
  }

  private initBackground(): void {
    this.popupBackground = new rewardsPopupBackgroundAnimated(this);
  }

  protected init(): void {
    this.addChild(this.popupBackground);
  }

  private setTextByPattern(value: number): {
    text_value?: string;
    text_value_styles?: Partial<ITextStyle>;
    text_value_filters?: Filter[];
    text_1?: string | undefined;
    text_1_styles?: Partial<ITextStyle>;
    text_1_filters?: Filter[];
    text_2?: string | undefined;
    text_2_styles?: Partial<ITextStyle>;
    text_2_filters?: Filter[];
  } {
    AudioApi.play({ type: ISongs.Particle_start });
    switch (this.props.pattern) {
      case CardAbilitiesPattern.NM:
        return {
          text_value: `${value}`,
          text_value_styles: masterTextValueStyle,
          text_1: i18n.t('winMasterPacks_1'),
          text_1_styles: masterText1Style,
          text_2: i18n.t('winMasterPacks_2'),
          text_2_styles: masterText2Style,
        };
      case CardAbilitiesPattern.Np:
        return {
          text_value: `${value}`,
          text_value_styles: freeTextValueStyle,
          text_1: i18n.t('winFreePacks_1'),
          text_1_styles: freeText1Style,
          text_2: i18n.t('winFreePacks_2'),
          text_2_styles: freeText2Style,
        };
      case CardAbilitiesPattern.Nup:
        return {
          text_1: i18n.t('winRarity_1'),
          text_1_styles: rarityText1Style,
          text_2: i18n.t('winRarity_2'),
          text_2_styles: rarityText2Style,
        };
      case CardAbilitiesPattern.NupA:
        return {
          text_1: i18n.t('winALLRarity_1'),
          text_1_styles: rarityText1Style,
          text_2: i18n.t('winALLRarity_2'),
          text_2_styles: rarityText2Style,
        };
      case CardAbilitiesPattern.Nx:
        return {
          text_value: `${value}`,
          text_value_styles: multiplierTextValueStyle,
        };
      case CardAbilitiesPattern.NxA:
        return {
          text_value: `${value}`,
          text_value_styles: multiplierTextValueStyle,
          text_1: i18n.t('winAllMultiplier'),
          text_1_styles: multiplierText1Style,
        };
      default:
        return {};
    }
  }

  private initText(value: number): void {
    const slotByPattern = this.popupBackground.spine.skeleton.findSlot(`${this.props.pattern}`).currentSprite as Sprite;
    if (!slotByPattern.children.length) {
      const textValue = new Text(
        this.setTextByPattern(value).text_value,
        this.setTextByPattern(value).text_value_styles,
      );
      textValue.anchor.set(0, 0.5);
      const textTitle1 = new Text(this.setTextByPattern(value).text_1, this.setTextByPattern(value).text_1_styles);
      textTitle1.anchor.set(0, 1);
      const textTitle2 = new Text(this.setTextByPattern(value).text_2, this.setTextByPattern(value).text_2_styles);
      textTitle2.anchor.set(0, 0);
      this.setTextPositionByPattern(textValue, textTitle1, textTitle2);
      slotByPattern.texture = Texture.EMPTY;
      const container = new Container();
      container.addChild(textValue, textTitle1, textTitle2);
      container.scale.set(0.8);
      slotByPattern.addChild(container);
    } else {
      const textContainer = slotByPattern.children[0] as Container;
      const storedTextValue = textContainer.children[0] as Text;
      storedTextValue.text = this.setTextByPattern(value).text_value!;
      const storedTextTitle1 = textContainer.children[1] as Text;
      storedTextTitle1.text = this.setTextByPattern(value).text_1!;
      const storedTextTitle2 = textContainer.children[2] as Text;
      storedTextTitle2.text = this.setTextByPattern(value).text_2!;
      storedTextValue.scale.set(0.8);
      storedTextTitle1.scale.set(0.8);
      storedTextTitle2.scale.set(0.8);
      this.setTextPositionByPattern(storedTextValue, storedTextTitle1, storedTextTitle2);
    }
  }

  private setTextPositionByPattern(textValue: Text, textTitle1: Text, textTitle2: Text): void {
    switch (this.props.pattern) {
      case CardAbilitiesPattern.NM: {
        textTitle1.y = 0;
        textTitle2.y = 40;
        textTitle1.x = textValue.width + 20;
        textTitle2.x = textValue.width + 20;
        break;
      }
      case CardAbilitiesPattern.Np: {
        textTitle1.y = 0;
        textTitle2.y = 40;
        textTitle1.x = textValue.width + 20;
        textTitle2.x = textValue.width + 20;
        break;
      }
      case CardAbilitiesPattern.Nup: {
        textTitle1.y = 0;
        textTitle2.y = 40;
        textTitle1.x = 0;
        textTitle2.x = 0;
        break;
      }
      case CardAbilitiesPattern.NupA: {
        textTitle1.y = 60;
        textTitle2.y = 100;
        textTitle1.x = 0;
        textTitle2.x = 12;
        break;
      }
      case CardAbilitiesPattern.Nx: {
        textTitle1.y = 0;
        textTitle2.y = 0;
        textTitle1.x = 0;
        textTitle2.x = 0;
        break;
      }
      case CardAbilitiesPattern.NxA: {
        textTitle1.y = 85;
        textTitle2.y = 0;
        textTitle1.x = textValue.width + 20;
        textTitle2.x = textValue.width + 20;
        break;
      }
      default: {
        textTitle1.y = 0;
        textTitle2.y = 0;
        textTitle1.x = 0;
        textTitle2.x = 0;
      }
    }
  }

  private initPressToContinueText(): void {
    this.pressToContinueText.text.anchor.set(0.5, 0.5);
    const continueSlot = this.popupBackground.spine.skeleton.findSlot('press_anywhere_to_continue_place_holder')
      .currentSprite as Sprite;
    continueSlot.texture = Texture.EMPTY;
    const exists = continueSlot.children.length;
    if (this.props.pattern === CardAbilitiesPattern.Np || this.props.pattern === CardAbilitiesPattern.NM) {
      if (exists) {
        this.pressToContinueText.text.visible = true;
      } else {
        continueSlot.addChild(this.pressToContinueText.getText());
      }
    } else {
      if (exists) {
        this.pressToContinueText.text.visible = false;
      }
    }
  }

  public override show(_props?: RewardsPopupProps): void {
    this.props = _props as RewardsPopupProps;
    this.popupBackground.show(this.props);
    this.initText(_props!.value);
    this.initPressToContinueText();
    eventManager.emit(EventTypes.ENABLE_SKIP_ACTION);
    eventManager.once(EventTypes.SKIP_ACTION, () => {
      this.handleClose();
    });
    eventManager.once(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, this.handleClose);
    if (
      (this.props.pattern === CardAbilitiesPattern.NM || this.props.pattern === CardAbilitiesPattern.Np) &&
      (setBonusPackAutoPlay() || (!setIsStopOnFeatureWin() && setIsAutoSpins()))
    ) {
      this.autoSkipDelay = setTimeout(this.handleClose, 5000);
    } else if (this.props.pattern !== CardAbilitiesPattern.NM && this.props.pattern !== CardAbilitiesPattern.Np) {
      this.autoSkipDelay = setTimeout(this.handleClose, setIsTurboSpin() ? 1500 : 3000);
    }
  }

  private handleClose = () => {
    PopupController.the.closeCurrentPopup();
    clearTimeout(this.autoSkipDelay);
    eventManager.removeListener(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, this.handleClose);
    this.removeListener('click', this.handleClose);
    this.removeListener('touchstart', this.handleClose);
    this.popupBackground.hide(this.props);
    eventManager.emit(EventTypes.DISABLE_SKIP_ACTION);
  };
}
