import type { RemoteStorage } from '@phoenix7dev/setting-store-client';

import type { ICardAbility, IInitAbility } from '../d';

import { CardClass, CardLevel, SlotId } from './config';
import { ReelSetType } from './gql/d';
import { Icon, LineSet } from './slotMachine/d';

export interface IUserBalance {
  id: string;
  clientId: string;
  balance: {
    amount: number;
    currency: string;
  };
}

export interface IAuthInput {
  clientId: string;
  token: string;
  slotId: string;
  lng: string;
  home: string;
}

export interface IGetBonusesInput {
  id: string;
  purchasable: boolean;
}

declare global {
  interface Window {
    __ENV__: {
      ENV: string;
      STATIC_CDN_URL: string;
      STATIC_CDN_DIRECTORY: string;
      APP_VERSION: string;
      APP_NAME: string;
      SENTRY_ENABLED: boolean;
      SENTRY_DSN: string;
      NETWORK_RETRY_ATTEMPTS: number;
      NETWORK_RETRY_DELAY: number;
    };
    __PIXI_APP__: PIXI.Application;
    eventManager: PIXI.utils.EventEmitter;
    PIXI: PIXI.Application;
    remoteStorage: RemoteStorage;
  }
}

export interface IBuyBonusesInput {
  id: string;
  coinValue: number;
}

export interface Payline {
  lineId: number | null;
  winPositions: number[];
  amount: number;
}

export interface ICardFeaturesData {
  id: number;
  name: SlotId;
  class: CardClass;
  level: CardLevel;
  price: number;
  initAbility: IInitAbility[];
  abilities: ICardAbility[];
}

export interface ISettledBet {
  bet: {
    id: string;
    coinAmount: number;
    coinValue: number;
    slotId: string;
    result: {
      reelPositions: number[];
      winCoinAmount: number;
      spinResult: Icon[];
    };
    userBonusId: string;
    userBonus: {
      id: string;
      bonusId: string;
      lineSet: LineSet;
    };
    reelSetId: string;
    reelSet: ReelSet;
    lineSet: LineSet;
    data: {
      bonuses: UserBonus[];
      features: {
        NM: number;
        Np: number;
        Nup: ICardFeaturesData[];
        NupA: ICardFeaturesData[];
        Nx: ICardFeaturesData[];
        NxA: ICardFeaturesData[];
        base: ICardFeaturesData[];
      };
    };
    createdAt: string;
    updatedAt: string;
  };
  paylines: Payline[];
  balance: {
    placed: {
      amount: number;
      currency: string;
    };
    settled: {
      amount: number;
      currency: string;
    };
  };
}

export type ReelSet = {
  id: string;
  layout: SlotId[][];
  type: ReelSetType;
  additionalReelSets: SlotId[][];
};

export type UserBonus = {
  bonusId: string;
  id: string;
  isActive: boolean;
  gameMode: GameMode;
  currentRound: number;
  betId: string;
  coinAmount: number;
  coinValue: number;
  rounds: number;
  roundsPlayed: number;
  status: BonusStatus;
  isFreeBet?: boolean;
  data: {
    count: number;
    creditMultiplier: number;
    debitMultiplier: number;
    storeCoinAmount: boolean;
    storeCoinValue: boolean;
    frbReferenceId: null | string;
  };
  reelSetId?: string;
  totalWinAmount: number;
};

export enum GameMode {
  BASE_GAME,
  FREE_PACKS,
  BUY_FEATURE,
  MASTER_PACKS,
  FREE_ROUND_BONUS,
}

export type BuyFeatureEnterProps = {
  bonusId: string;
};
export type FreeSpinsEnterProps = {
  bonus: UserBonus;
};
export type EnterProps = (BuyFeatureEnterProps | FreeSpinsEnterProps) & {
  immediate?: boolean;
  endBonus?: boolean;
  skipIdle?: boolean;
};

export const baseReelSetIds = ['42fdd893-4626-442a-a7b8-381fd6a78094'];

export const freePacksReelSetIds = ['7278b674-9d16-405c-96db-efefa8f45078'];

export const masterPacksReelSetIds = ['356569e3-71ff-41d4-8dde-f50968ed4d36'];

export const buyFeatureReelSetIds = ['1c0a8fa6-d165-4fbb-bd84-a57f808ab693'];

export enum FeatureState {
  FREE_PACKS,
  MASTER_PACKS,
  BUY_FEATURE,
}

export const bonusesId = {
  [GameMode.FREE_PACKS]: 'e958ac50-b2b4-4195-9d21-19c2b3a1fee4',
  [GameMode.MASTER_PACKS]: '48dd608c-ae3a-4bc0-a420-3b6dcaef2c0b',
  [GameMode.BUY_FEATURE]: '6dfbf930-d898-4334-bc5f-5139f5465ba0',
  [GameMode.FREE_ROUND_BONUS]: '65a57ad1-3fd9-45f7-a16b-e9f896ab3e55',
};
export const buyFeatureBonusesId = {
  [FeatureState.FREE_PACKS]: 'c4c26a3c-f513-46ab-b972-b525fc6217f8',
  [FeatureState.MASTER_PACKS]: '9d8b2a7e-216b-4f3a-8de0-0b6a683a53f4',
};

export interface IBonus {
  id: string;
  type: string;
  coinAmount: number;
  isActive: boolean;
  bonusId: string;
  roundsPlayed: number;
  currentRound: number;
  rounds: number;
  reelSetId: string;
  totalWinAmount: number;
  betId: string;
}
export interface GetUserBonusesInput {
  id: string;
  status: BonusStatus;
}

export type FreeSpinsTitleProps = {
  text: string;
  spins: string;
  currentSpin: string;
};

export type MessageBannerProps = {
  title?: string;
  titlePosition?: number;
  mobileTitlePosition?: number;
  titleStyles?: TextStyle;
  mobileTitleStyle?: TextStyle;
  subtitle?: string;
  subtitlePosition?: number;
  mobileSubtitlePosition?: number;
  subtitleStyles?: TextStyle;
  mobileSubtitleStyle?: TextStyle;
  btnText?: string;
  additionalText?: string;
  additionalPosition?: number;
  additionalStyles?: TextStyle;
  mobileAdditionalStyle?: TextStyle;
  preventDefaultDestroy?: boolean;
  mobileSubtitleBorderWidth?: number;
  mobileSubtitleBorderHeight?: number;
  subtitleBorderWidth?: number;
  subtitleBorderHeight?: number;
  callback?: () => void;
  onInitCallback?: () => void;
};

export enum EventTypes {
  BROKEN_GAME = 'brokenGame',
  END_BIG_WIN_PRESENTATION = 'endBigWinPresentation',
  CHANGE_MODE = 'changeMode',
  CHANGE_STATE = 'changeState',
  COUNT_UP_END = 'countUpEnd',
  END_TWEEN_ANIMATION = 'endTweenAnimation',
  END_FREE_PACK_OPENING = 'endFreePackOpening',
  ENABLE_SOUND_LOADER = 'enableSoundLoader',
  FORCE_FLIP = 'forceFlip',
  FORCE_RESIZE = 'forceResize',
  GAME_READY = 'gameReady',
  HIDE_COUNT_UP = 'hideCountUp',
  HIDE_WIN_LABEL = 'hideWinLabel',
  HANDLE_BUY_BONUS = 'handleBuyBonus',
  HANDLE_UPDATE_FREE_SPINS_TITLE = 'handleUpdateFreeSpinsTitle',
  NEXT_FREE_PACK_OPEN = 'nextFreePackOpen',
  NEXT_MASTER_PACK_OPEN = 'nextMasterPackOpen',
  SPACEKEY_CLOSE_MESSAGE_BANNER = 'spaceKeyCloseMessageBanner',
  ALL_CARDS_IS_OPENED = 'allCardsIsOpened',
  CARD_IS_OPENED = 'cardIsOpened',
  RESIZE = 'resize',
  RESIZE_GAME_CONTAINER = 'resizeGameContainer',
  REMOVE_TWEEN_ANIMATION = 'removeTweenAnimation',
  START_TWEEN_ANIMATION = 'startTweenAnimation',
  DISPLAY_INITIAL_PACK = 'displayInitialPack',
  START_MODE_CHANGE_FADE = 'startModeChangeFade',
  START_BUY_FEATURE_ROUND = 'startBuyFeatureRound',
  START_COUNT_UP = 'startCountUp',
  START_BIG_WIN_PRESENTATION = 'startBigWinPresentation',
  START_FREE_PACK_OPENING = 'startFreePackOpening',
  SHOW_WIN_LABEL = 'showWinLabel',
  SOUND_INITIALIZED = 'soundInitialized',
  SKIP_ALL_WIN_ANIMATIONS = 'skipAllWinAnimations',
  SKIP_WIN_COUNT_UP_ANIMATION = 'skipWinCountUpAnimation',
  THROW_ERROR = 'throwError',
  UPDATE_BET = 'updateBet',
  UPDATE_USER_BALANCE = 'updateUserBalance',
  UPDATE_TOTAL_WIN_VALUE = 'updateTotalWinValue',
  UPDATE_WIN_VALUE = 'updateWinValue',
  POST_RENDER = 'postrender',
  OPEN_POPUP = 'openPopup',
  OPEN_POPUP_BG = 'openPopupBg',
  CLOSE_POPUP = 'closePopup',
  CLOSE_POPUP_BG = 'closePopupBg',
  DISABLE_BUY_FEATURE_BTN = 'disableBuyFeatureBtn',
  SET_IS_LEFT_HAND_MODE = 'setIsLeftHandMode',
  SET_IS_AUTO_SPINS = 'setIsAutoSpins',
  SET_AUTO_SPINS_LEFT = 'setAutoSpinsLeft',
  SET_IS_SLOT_IS_BUSY = 'isSlotBusy',
  SET_IS_FREESPINS_WIN = 'isFreeSpinWin',
  TOGGLE_SOUND = 'toggleSound',
  TOGGLE_TURBO_SPIN = 'toggleTurboSpin',
  TOGGLE_SPIN = 'toggleSpin',
  HANDLE_DESTROY_INTRO_SCREEN = 'handleDestroyIntroScreen',
  ABILITIES_END_NM = 'abilitiesEndNM',
  ABILITIES_END_NP = 'ABILITIES_END_NP',
  ABILITIES_END_NUP = 'ABILITIES_END_NUP',
  ABILITIES_END_NUPA = 'ABILITIES_END_NUPA',
  ABILITIES_END_NX = 'ABILITIES_END_NX',
  ABILITIES_END_XNA = 'ABILITIES_END_XNA',
  ABILITIES_ANIMATION_START_NM_1 = 'ABILITIES_ANIMATION_START_NM_1',
  ABILITIES_ANIMATION_START_NM_2 = 'ABILITIES_ANIMATION_START_NM_2',
  ABILITIES_ANIMATION_START_NM_3 = 'ABILITIES_ANIMATION_START_NM_3',
  ABILITIES_ANIMATION_START_NM_4 = 'ABILITIES_ANIMATION_START_NM_4',
  ABILITIES_ANIMATION_START_NM_5 = 'ABILITIES_ANIMATION_START_NM_5',
  ABILITIES_ANIMATION_START_NP_1 = 'ABILITIES_ANIMATION_START_NP_1',
  ABILITIES_ANIMATION_START_NP_2 = 'ABILITIES_ANIMATION_START_NP_2',
  ABILITIES_ANIMATION_START_NP_3 = 'ABILITIES_ANIMATION_START_NP_3',
  ABILITIES_ANIMATION_START_NP_4 = 'ABILITIES_ANIMATION_START_NP_4',
  ABILITIES_ANIMATION_START_NP_5 = 'ABILITIES_ANIMATION_START_NP_5',
  ABILITIES_ANIMATION_START_NUP_1 = 'ABILITIES_ANIMATION_START_NUP_1',
  ABILITIES_ANIMATION_START_NUP_2 = 'ABILITIES_ANIMATION_START_NUP_2',
  ABILITIES_ANIMATION_START_NUP_3 = 'ABILITIES_ANIMATION_START_NUP_3',
  ABILITIES_ANIMATION_START_NUP_4 = 'ABILITIES_ANIMATION_START_NUP_4',
  ABILITIES_ANIMATION_START_NUP_5 = 'ABILITIES_ANIMATION_START_NUP_5',
  ABILITIES_ANIMATION_START_NUPA_1 = 'ABILITIES_ANIMATION_START_NUPA_1',
  ABILITIES_ANIMATION_START_NUPA_2 = 'ABILITIES_ANIMATION_START_NUPA_2',
  ABILITIES_ANIMATION_START_NUPA_3 = 'ABILITIES_ANIMATION_START_NUPA_3',
  ABILITIES_ANIMATION_START_NUPA_4 = 'ABILITIES_ANIMATION_START_NUPA_4',
  ABILITIES_ANIMATION_START_NUPA_5 = 'ABILITIES_ANIMATION_START_NUPA_5',
  ABILITIES_ANIMATION_START_NX_1 = 'ABILITIES_ANIMATION_START_NX_1',
  ABILITIES_ANIMATION_START_NX_2 = 'ABILITIES_ANIMATION_START_NX_2',
  ABILITIES_ANIMATION_START_NX_3 = 'ABILITIES_ANIMATION_START_NX_3',
  ABILITIES_ANIMATION_START_NX_4 = 'ABILITIES_ANIMATION_START_NX_4',
  ABILITIES_ANIMATION_START_NX_5 = 'ABILITIES_ANIMATION_START_NX_5',
  ABILITIES_ANIMATION_START_XNA_1 = 'ABILITIES_ANIMATION_START_XNA_1',
  ABILITIES_ANIMATION_START_XNA_2 = 'ABILITIES_ANIMATION_START_XNA_2',
  ABILITIES_ANIMATION_START_XNA_3 = 'ABILITIES_ANIMATION_START_XNA_3',
  ABILITIES_ANIMATION_START_XNA_4 = 'ABILITIES_ANIMATION_START_XNA_4',
  ABILITIES_ANIMATION_START_XNA_5 = 'ABILITIES_ANIMATION_START_XNA_5',
  ABILITIES_ANIMATION_END_NM = 'ABILITIES_ANIMATION_END_NM',
  ABILITIES_ANIMATION_END_NP = 'ABILITIES_ANIMATION_END_NP',
  ABILITIES_ANIMATION_END_NUP = 'ABILITIES_ANIMATION_END_NUP',
  ABILITIES_ANIMATION_END_NUPA = 'ABILITIES_ANIMATION_END_NUPA',
  ABILITIES_ANIMATION_END_NX = 'ABILITIES_ANIMATION_END_NX',
  ABILITIES_ANIMATION_END_XNA = 'ABILITIES_ANIMATION_END_XNA',
  UPDATE_CARD = 'UPDATE_CARD',
  PHOENIX_ANTICIPATION_START = 'phoenixStart',
  PHOENIX_ANTICIPATION_END = 'phoenixEnd',
  WIN_MASTER_PACKS = 'WIN_MASTER_PACKS',
  WIN_FREE_PACKS = 'WIN_FREE_PACKS',
  BASE_GAME_PACKS = 'BASE_GAME_PACKS',
  ADD_WIN_MASTER_PACKS = 'ADD_WIN_MASTER_PACKS',
  UPDATE_WIN_MASTER_PACKS = 'UPDATE_WIN_MASTER_PACKS',
  UPDATE_WIN_FREE_PACKS = 'UPDATE_FREE_PACKS',
  ADD_WIN_FREE_PACKS = 'ADD_WIN_FREE_PACKS',
  SHOW_FINAL_WIN_POPUP = 'showFinalWinPopup',
  HIDE_FINAL_WIN_POPUP = 'hideFinalWinPopup',
  UPDATE_SPIN_BUTTON_INTENT = 'updateSpinButtonIntent',
  HIDE_CARD = 'HIDE_CARD',
  UPDATE_BACKGROUND = 'UPDATE_BACKGROUND',
  UPDATE_CARD_BACKGROUND = 'UPDATE_CARD_BACKGROUND',
  UPDATE_BONUS_REWARDS = 'UPDATE_BONUS_REWARDS',
  GET_NEW_CARD = 'GET_NEW_CARD',
  APPLY_ABILITY = 'APPLY_ABILITY',
  ABILITY_APPLIED = 'ABILITY_APPLIED',
  SKIP_ACTION = 'SKIP_ACTION',
  ENABLE_SKIP_ACTION = 'ENABLE_SKIP_ACTION',
  DISABLE_SKIP_ACTION = 'DISABLE_SKIP_ACTION',
  HANDLE_CARD_WIN_ANIMATION = 'HANDLE_CARD_WIN_ANIMATION',
  PARENT_MODAL_RESIZE = 'PARENT_MODAL_RESIZE',
  CHILD_MODAL_RESIZE = 'CHILD_MODAL_RESIZE',
  CARDS_INITIALIZED = 'CARDS_INITIALIZED',
  ROLLBACK_STATE = 'ROLLBACK_STATE',
  FREE_ROUND_BONUS_EXPIRED = 'freeRoundBonusExpired',
  START_FREE_ROUND_BONUS = 'startFreeRoundBonus',
  END_FREE_ROUND_BONUS = 'endFreeRoundBonus',
  UPDATE_FREE_ROUNDS_LEFT = 'updateFreeRoundsLeft',
  FORCE_STOP_AUTOPLAY = 'forceStopAutoplay',
}

export enum BonusStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SETTLED = 'SETTLED',
}

export enum FeatureTypes {
  SPECIAL_ROUND = 'SPECIAL_ROUND',
  FREE_SPIN = 'FREE_SPIN',
}
export enum GraphQLErrorsType {
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
}

export enum ModalOpeningTypes {
  MENU = 'MENU',
  AUTOPLAY = 'AUTOPLAY',
  BET_SETTINGS = 'BET_SETTINGS',
  BUY_FEATURE_POPUP = 'BUY_FEATURE_POPUP',
  NONE = null,
}
