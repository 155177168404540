import MultiStyleText from 'pixi-multistyle-text';
import { Container, isMobile } from 'pixi.js';

import { CardClass, CardLevel } from '../../config';
import { EventTypes } from '../../global.d';
import { setEnabledCards } from '../../gql/cache';
import i18n from '../../i18next';
import { ModalService } from '../ModalService';
import { eventManager } from '../config';
import LibraryBtn from '../controlButtons/libraryBtn';

import { getCardDescriptionTextStyles, levelNames } from './collection.model';
import { CollectionCard } from './collectionCard';
import { DescriptionPlate } from './descriptionPlate';

export class CardInfo extends Container {
  public override name = 'CardInfo';
  public override interactive = true;
  constructor(level: CardLevel, type: CardClass) {
    super();
    const card = new CollectionCard(level, type, false, () => {}, { width: 420, height: 588 });

    const descriptionPlate = new DescriptionPlate(level, type);
    const description = this.getDescription(level);

    this.addChild(description, card, descriptionPlate, this.getLibraryBtn());
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const isPortrait = isMobile.any && screenHeight > screenWidth;
    this.setCardsInfoContainerSizes(
      description,
      card,
      descriptionPlate,
      isPortrait ? screenHeight : screenWidth,
      isPortrait ? screenWidth : screenHeight,
    );

    eventManager.on(EventTypes.CHILD_MODAL_RESIZE, (width, height) => {
      this.setCardsInfoContainerSizes(description, card, descriptionPlate, width, height);
    });

    this.on('click', () => {
      ModalService.the.hide();
    });
    this.on('touchstart', () => {
      ModalService.the.hide();
    });
  }

  private getLibraryBtn(): Container {
    const libraryBtn = new LibraryBtn(() => ModalService.the.hide());
    libraryBtn.setDisable(false);

    // Workaround need to render controlButton, problem with realization
    const resizeEvent = new Event('resize');
    window.dispatchEvent(resizeEvent);
    return libraryBtn;
  }

  private setCardsInfoContainerSizes(
    description: Container,
    card: Container,
    descriptionPlate: Container,
    width: number,
    height: number,
  ): void {
    if (this.children.length) {
      const scale = Math.min(height / 1080, 1);
      card.scale.set(scale);
      description.scale.set(scale);
      descriptionPlate.scale.set(scale);
      card.x = width / 2 - card.width / 2;
      card.y = height / 2 - card.height / 2;
      description.x = card.x - card.width / 1.5 - 32;
      descriptionPlate.x = card.x + card.width + 32;
      description.y = height / 2 - description.height / 2;
      descriptionPlate.y = height / 2 - descriptionPlate.height / 2;
    } else {
      eventManager.removeListener(EventTypes.CHILD_MODAL_RESIZE);
      this.removeAllListeners();
    }
  }

  private fitDescriptionText(textField: MultiStyleText, maxWidth: number, level: CardLevel) {
    let fontSize = Number(textField.style.fontSize);

    while (textField.width > maxWidth && Number(textField.style.fontSize) > 10) {
      fontSize -= 1;
      textField.styles = getCardDescriptionTextStyles(level, fontSize);
      textField.updateText();
    }
  }

  private getDescription(level: CardLevel): Container {
    const message = [CardLevel.Cm, CardLevel.Un].includes(level) ? 'cmUnCardDescription' : 'cardDescription';
    const unlockedCards = setEnabledCards();
    const numberOfAllLevelCard = Object.values(CardClass).length;
    const numberOfUnlockedLevelCard = unlockedCards.filter((slotId) => slotId.includes(level)).length;

    const descriptionText = new MultiStyleText(
      i18n.t<string>(message, {
        rarity: levelNames[level],
        count: numberOfUnlockedLevelCard,
        total: numberOfAllLevelCard,
      }),
      getCardDescriptionTextStyles(level),
    );

    this.fitDescriptionText(descriptionText, 250, level);

    return descriptionText;
  }
}
