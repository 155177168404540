/* eslint-disable security/detect-object-injection */
import i18n from 'i18next';
import { takeUntil } from 'rxjs';

import AudioApi from '@phoenix7dev/audio-api';

import {
  CardAbilitiesPattern,
  CardClass,
  CardLevel,
  ISongs,
  SlotId,
  eventPatternMappingsAnimationEnd,
  eventPatternMappingsAnimationStart,
} from '../../config';
import { EnterProps, EventTypes, GameMode, ICardFeaturesData, ISettledBet, UserBonus, bonusesId } from '../../global.d';
import {
  setAutoSpinsLeft,
  setBetAmount,
  setBetResult,
  setBonusPackAutoPlay,
  setBottomContainerTotalWin,
  setBrokenGame,
  setCardsDisappeared,
  setCardsOpeningInprogress,
  setCoinAmount,
  setCoinValue,
  setCurrentBonus,
  setCurrentBonusId,
  setFreePacksBonus,
  setFreeRoundsBonus,
  setFreeRoundsTotalWin,
  setFreeSpinsTotalWin,
  setIsAutoSpins,
  setIsFreeSpinsWin,
  setIsSlotBusy,
  setLastRegularWinAmount,
  setLastSpinData,
  setMasterPacksBonus,
  setSlotConfig,
  setStressful,
  setUserBalance,
} from '../../gql/cache';
import { PopupTypes, WinStages, eventManager } from '../../slotMachine/config';
import type { ICardAbility } from '../../slotMachine/d';
import { PopupController } from '../../slotMachine/popups/PopupController';
import { RewardAction, RewardsActions } from '../../slotMachine/popups/rewardsPopup/rewardsActions';
import { getBetResult, getWinStage, isRegularMode } from '../../utils';
import { States } from '../config';
import { Logic } from '../index';

import { Controller } from './Controller';

const processingOrder = [
  CardAbilitiesPattern.NM,
  CardAbilitiesPattern.Np,
  CardAbilitiesPattern.NupA,
  CardAbilitiesPattern.Nup,
  CardAbilitiesPattern.Nx,
  CardAbilitiesPattern.NxA,
] as CardAbilitiesPattern[];
export class FreeRoundsController extends Controller {
  public gameMode: GameMode = GameMode.FREE_ROUND_BONUS;

  public static the = new FreeRoundsController();

  private skipIdle = false;

  private slotIdleTimeout: ReturnType<typeof setTimeout> | undefined;

  protected constructor() {
    super();

    eventManager.on(EventTypes.FREE_ROUND_BONUS_EXPIRED, () => {
      PopupController.the.closeCurrentPopup();
      Logic.the.changeState(States.TRANSITION);
      Logic.the.changeGameMode(GameMode.BASE_GAME);
      setCurrentBonus({ ...setCurrentBonus(), isActive: false });
    });
  }

  public override enterIdleState(prevState: States): void {
    if (setIsAutoSpins() && setAutoSpinsLeft() <= 0) {
      setIsAutoSpins(false);
      setBonusPackAutoPlay(false);
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    }
    if (prevState === States.PACK_OPEN) {
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
      setIsSlotBusy(false);
      return;
    }
    if (prevState === States.TRANSITION) {
      if (setCurrentBonusId()) {
        return;
      }
    }

    if (prevState === States.INIT || prevState === States.INTRO || prevState === States.BROKEN_GAME) {
      // const debug = new Debug();
      // debug.x = 800;
      // Logic.the.application.stage.addChild(debug);
      // Logic.the.application.ticker.add(() => debug.update());
      return;
    }
    if (this.skipIdle) {
      this.skipIdle = false;
      return;
    }
    if (setCurrentBonus().currentRound === setCurrentBonus().rounds || !setCurrentBonus().isActive) {
      setFreeRoundsBonus({ ...setFreeRoundsBonus(), isActive: false });
      setCurrentBonus({ ...setCurrentBonus(), isActive: false });
      PopupController.the.openPopup(PopupTypes.FREE_ROUNDS_END);
      eventManager.emit(EventTypes.FORCE_STOP_AUTOPLAY);
      eventManager.once(EventTypes.END_FREE_ROUND_BONUS, () => {
        PopupController.the.closeCurrentPopup();
        Logic.the.changeState(States.TRANSITION);
        Logic.the.changeGameMode(GameMode.BASE_GAME);
      });
      return;
    }
    this.slotIdleTimeout = setTimeout(() => {
      AudioApi.play({ type: ISongs.BGM_BG_Melo_Loop });
    }, 20000);
    Logic.the.isReadyForFlip = false;
    setIsSlotBusy(false);
    // eventManager.emit(EventTypes.UPDATE_USER_BALANCE, getBetResult(setBetResult()).balance.settled);
    // this.handleHistory();
  }
  public override enterOpenPackState(_prevState: States): void {
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
    setIsSlotBusy(true);
    clearTimeout(this.slotIdleTimeout);
  }

  public override enterBeforeWinState(_prevState: States): void {
    Logic.the.isReadyForFlip = true;
    setIsSlotBusy(false);
  }

  public override enterWinPresentationState(_prevState: States): void {
    const betResult: ISettledBet = getBetResult(setBetResult());
    Logic.the.isReadyForFlip = false;
    const hasAbilities = betResult.bet.data.features.base.some((element) => element.abilities);
    if (hasAbilities) {
      Logic.the.changeState(States.APPEND_ABILITIES);
    } else {
      Logic.the.changeState(States.AFTER_WIN);
    }
  }

  private handleAbility = async (
    cardData: ICardFeaturesData,
    resolvePatternStep: () => void,
    lastCard: boolean,
    cardIndex: number,
    pattern: CardAbilitiesPattern,
    isLastPattern: boolean,
  ): Promise<void> => {
    return new Promise((resolve) => {
      const betResult: ISettledBet = getBetResult(setBetResult());
      const cardAbility: ICardAbility = cardData?.abilities?.find(
        (item: ICardAbility) => item.pattern === pattern,
      ) as ICardAbility;
      if (cardAbility) {
        let outId: string | number = 1;
        if (pattern !== CardAbilitiesPattern.Np && pattern !== CardAbilitiesPattern.NM) {
          if (pattern === CardAbilitiesPattern.NxA) {
            outId = betResult.bet.data.features[pattern].filter((e) => e.price).length;
          } else if (pattern === CardAbilitiesPattern.NupA) {
            outId = cardIndex + 1;
          } else {
            outId =
              betResult.bet.data.features[pattern].findIndex(
                (e) => e.initAbility.length && e.initAbility.find((e) => e.id === cardIndex),
              ) + 1;
          }
        }

        eventManager.emit(
          eventPatternMappingsAnimationStart[
            (pattern + '_' + (cardIndex + 1)) as keyof typeof eventPatternMappingsAnimationStart
          ] as unknown as string,
          pattern,
          cardIndex,
        );
        eventManager.on(
          eventPatternMappingsAnimationEnd[
            pattern as keyof typeof eventPatternMappingsAnimationEnd
          ] as unknown as string,
          (id: number) => {
            if (id === cardIndex) {
              PopupController.the.openPopup(PopupTypes.REWARD_POPUP, {
                pattern,
                value: cardAbility.value,
                inId: cardIndex + 1,
                outId,
              });
              eventManager.removeListener(
                eventPatternMappingsAnimationEnd[
                  pattern as keyof typeof eventPatternMappingsAnimationEnd
                ] as unknown as string,
              );
            }
          },
        );
        RewardsActions.the.rewardsPopupObservable
          .pipe(takeUntil(RewardsActions.the.destroyObservable))
          .subscribe((data: RewardAction) => {
            if (pattern !== CardAbilitiesPattern.NM && pattern !== CardAbilitiesPattern.Np) {
              betResult.bet.data.features[pattern].forEach((card, index) => {
                const newCard: {
                  id: number;
                  cardName: SlotId;
                  cardClass: CardClass;
                  cardLevel: CardLevel;
                  cardPrice: number;
                } = {
                  id: index,
                  cardName: card.name,
                  cardClass: card.class,
                  cardLevel: card.level,
                  cardPrice: card.price,
                };
                if (card.initAbility?.length) {
                  const ability = card.initAbility.find((e) => e.id === cardIndex);
                  if (ability) {
                    newCard.cardPrice = ability.price;
                    if (card.initAbility.length > 1) {
                      if (pattern === CardAbilitiesPattern.Nx) {
                        card.initAbility[1].price += ability.price;
                      } else if (pattern === CardAbilitiesPattern.NxA) {
                        const nX = betResult.bet.data.features['Nx'].find((e) => e.id === card.id);
                        if (nX?.initAbility?.length) {
                          card.initAbility[0].price += nX?.initAbility[0].price;
                        }
                        newCard.cardPrice = card.initAbility[0].price;
                        card.initAbility[1].price += card.initAbility[0].price;
                      } else if (pattern === CardAbilitiesPattern.Nup || pattern === CardAbilitiesPattern.NupA) {
                        newCard.cardName = (card.initAbility[0].value + newCard.cardClass) as SlotId;
                        newCard.cardLevel = card.initAbility[0].value;
                      }
                      card.initAbility = card.initAbility.splice(1);
                    } else if (card.initAbility.length === 1 && pattern === CardAbilitiesPattern.NxA) {
                      newCard.cardPrice = card.price;
                    }
                    eventManager.emit(EventTypes.UPDATE_CARD, newCard);
                  }
                }
              });
            }
            setTimeout(
              () => {
                resolve();
                if (lastCard) {
                  resolvePatternStep();
                  if (isLastPattern) {
                    Logic.the.changeState(States.AFTER_WIN);
                  }
                }
              },
              pattern === CardAbilitiesPattern.NxA || pattern === CardAbilitiesPattern.NupA ? 500 : 200,
            );
            if (data.index === cardIndex + 1 && data.pattern === pattern) {
              RewardsActions.the.unsubscribe();
            }
          });
      } else {
        resolve();
        if (lastCard) {
          resolvePatternStep();
          if (isLastPattern) {
            Logic.the.changeState(States.AFTER_WIN);
          }
        }
      }
    });
  };

  private handleAbilities = async (
    cardArr: ICardFeaturesData[],
    resolvePatternStep: () => void,
    pattern: CardAbilitiesPattern,
    isLastPattern: boolean,
  ) => {
    for (let i = 0; i < cardArr.length; i++) {
      const lastCard = i === cardArr.length - 1;
      await this.handleAbility(cardArr[i]!, resolvePatternStep, lastCard, i, pattern, isLastPattern);
    }
  };

  private processElement = async (pattern: CardAbilitiesPattern, isLastPattern: boolean): Promise<void> => {
    const betResult: ISettledBet = getBetResult(setBetResult());

    return new Promise((resolve) => {
      const hasAbilities = betResult.bet.data.features.base.find((element) => {
        return element.abilities && element.abilities.some((ability: ICardAbility) => ability.pattern === pattern);
      });
      if (hasAbilities) {
        if (pattern === CardAbilitiesPattern.NM) {
          const cardArr = betResult.bet.data.features.base;
          this.handleAbilities(cardArr, resolve, pattern, isLastPattern);
        }
        if (pattern === CardAbilitiesPattern.Np) {
          const cardArr = betResult.bet.data.features.base;
          this.handleAbilities(cardArr, resolve, pattern, isLastPattern);
        }
        if (pattern === CardAbilitiesPattern.Nup || pattern === CardAbilitiesPattern.NupA) {
          const cardArr = betResult.bet.data.features[pattern];
          this.handleAbilities(cardArr, resolve, pattern, isLastPattern);
        }
        if (pattern === CardAbilitiesPattern.Nx || pattern === CardAbilitiesPattern.NxA) {
          const cardArr = betResult.bet.data.features[pattern];
          this.handleAbilities(cardArr, resolve, pattern, isLastPattern);
        }
      } else {
        resolve();
        if (isLastPattern) {
          Logic.the.changeState(States.AFTER_WIN);
        }
      }
    });
  };

  private processElements = async () => {
    const totalElements = processingOrder.length;
    for (let i = 0; i < totalElements; i++) {
      const pattern = processingOrder[i];
      const isLastPattern = i === totalElements - 1;
      await this.processElement(pattern!, isLastPattern);
    }
  };

  public override enterAppendAbilitiesState(_prevState: States): void {
    this.processElements();
  }

  public override enterAfterWinState(_prevState: States): void {
    const betResult: ISettledBet = getBetResult(setBetResult());
    const { winCoinAmount } = getBetResult(setBetResult()).bet.result;
    if (getWinStage(winCoinAmount) >= WinStages.BigWin) {
      eventManager.once(EventTypes.END_BIG_WIN_PRESENTATION, () => {
        setLastRegularWinAmount(winCoinAmount);
        Logic.the.changeState(States.JINGLE);
      });
      eventManager.emit(EventTypes.START_BIG_WIN_PRESENTATION, winCoinAmount);
    } else if (betResult.bet.result.winCoinAmount) {
      setLastRegularWinAmount(winCoinAmount);
      eventManager.emit(EventTypes.START_COUNT_UP, 0, betResult.bet.result.winCoinAmount, 0);
      eventManager.once(EventTypes.COUNT_UP_END, () => {
        eventManager.emit(EventTypes.HIDE_COUNT_UP);
        Logic.the.changeState(States.JINGLE);
      });
    } else {
      Logic.the.changeState(States.JINGLE);
    }
  }

  public override enterJingleState(_prevState: States): void {
    if (setBottomContainerTotalWin() > 0) {
      eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setBottomContainerTotalWin());
    }
    setCardsOpeningInprogress(false);
    setCardsDisappeared(true);
    const betResult = getBetResult(setBetResult());
    const bonuses = betResult.bet.data.bonuses;
    const freePacksBonus = bonuses.find((bonus) => bonus.bonusId === bonusesId[GameMode.FREE_PACKS]);
    const masterPacksBonus = bonuses.find((bonus) => bonus.bonusId === bonusesId[GameMode.MASTER_PACKS]);

    if (freePacksBonus) {
      setFreeRoundsBonus({ ...setCurrentBonus() });
      setFreeSpinsTotalWin(betResult.bet.result.winCoinAmount);
      setCurrentBonus({
        ...(freePacksBonus as UserBonus),
        isActive: true,
      });
      if (setAutoSpinsLeft() && setBonusPackAutoPlay()) {
        setAutoSpinsLeft(setFreePacksBonus().rounds + setMasterPacksBonus().rounds);
      }
      Logic.the.changeState(States.TRANSITION);
      Logic.the.changeGameMode(GameMode.FREE_PACKS);
      eventManager.emit(EventTypes.UPDATE_SPIN_BUTTON_INTENT);
    } else if (masterPacksBonus) {
      setFreeRoundsBonus({ ...setCurrentBonus() });
      setFreeSpinsTotalWin(betResult.bet.result.winCoinAmount);
      setCurrentBonus({
        ...(masterPacksBonus as UserBonus),
        isActive: true,
      });
      if (setAutoSpinsLeft() && setBonusPackAutoPlay()) {
        setAutoSpinsLeft(setFreePacksBonus().rounds + setMasterPacksBonus().rounds);
      }
      Logic.the.changeState(States.TRANSITION);
      Logic.the.changeGameMode(GameMode.MASTER_PACKS);
      eventManager.emit(EventTypes.UPDATE_SPIN_BUTTON_INTENT);
    } else {
      Logic.the.changeState(States.IDLE);
      eventManager.emit(EventTypes.UPDATE_SPIN_BUTTON_INTENT);
    }
  }

  public override enterController(prevGameMode: GameMode, props?: EnterProps): void {
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
    if (prevGameMode !== GameMode.FREE_PACKS && prevGameMode !== GameMode.MASTER_PACKS) {
      eventManager.emit(EventTypes.BASE_GAME_PACKS);
    }
    if (!AudioApi.isPlaying(ISongs.BGM_BG_Melo_Loop)) {
      AudioApi.play({ type: ISongs.BGM_BG_Melo_Loop });
    }
    if (prevGameMode === null) return;
    setIsFreeSpinsWin(false);
    if (setFreeRoundsBonus().isActive) {
      if (!props?.endBonus) {
        setCurrentBonus({ ...setFreeRoundsBonus() });
      } else {
        // because master and freepacks doesnot have currentRound, we manually say that all rounds were played
        setCurrentBonus({
          ...setCurrentBonus(),
          rounds: setFreeRoundsBonus().rounds,
          currentRound: setFreeRoundsBonus().rounds,
        });
      }
    }
    eventManager.emit(EventTypes.UPDATE_FREE_ROUNDS_LEFT, setCurrentBonus().rounds - setCurrentBonus().currentRound);
    setCoinValue(setCurrentBonus().coinValue);
    setCoinAmount(setCurrentBonus().coinAmount);
    setBetAmount(setCurrentBonus().coinAmount * setSlotConfig().lineSets[0]!.coinAmountMultiplier);

    if (prevGameMode === GameMode.FREE_PACKS || prevGameMode === GameMode.MASTER_PACKS) {
      // setWinAmount(setFreeSpinsTotalWin());
      // eventManager.emit(EventTypes.UPDATE_USER_BALANCE, getBetResult(setBetResult()).balance.settled);
      // this.handleHistory();
    }
    if (setBottomContainerTotalWin() > 0) {
      setTimeout(() => {
        eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setBottomContainerTotalWin());
      }, 0);
    }
    eventManager.once(EventTypes.START_FREE_ROUND_BONUS, () => {
      PopupController.the.closeCurrentPopup();
      Logic.the.changeState(States.IDLE);
    });
    if (props?.endBonus && setFreeRoundsBonus().rounds - setFreeRoundsBonus().currentRound > 0) {
      eventManager.emit(
        EventTypes.UPDATE_FREE_ROUNDS_LEFT,
        setFreeRoundsBonus().rounds - setFreeRoundsBonus().currentRound,
      );
      setStressful({
        show: true,
        type: 'network',
        message: i18n.t('errors.OPERATOR.INVALID_BONUS'),
        callback: () => {
          setFreeRoundsBonus({ ...setFreeRoundsBonus(), isActive: false });
          setCurrentBonus({ ...setCurrentBonus(), isActive: false });
          Logic.the.changeState(States.IDLE);
        },
      });
    } else if (setCurrentBonus().rounds - setCurrentBonus().currentRound === 0) {
      Logic.the.changeState(States.IDLE);
    } else if (!setFreeRoundsBonus().isActive || !setFreeRoundsBonus().currentRound) {
      PopupController.the.openPopup(PopupTypes.FREE_ROUNDS);
    } else {
      setCurrentBonus({ ...setCurrentBonus(), isActive: true });
      eventManager.emit(EventTypes.START_FREE_ROUND_BONUS);
    }
  }

  public override exitController(_nextGameMode: GameMode): void {
    clearTimeout(this.slotIdleTimeout);
  }

  public override setResult(result: ISettledBet): void {
    if (setBrokenGame()) setBrokenGame(false);
    if (isRegularMode(Logic.the.controller.gameMode)) {
      eventManager.emit(EventTypes.UPDATE_USER_BALANCE, result.balance.placed);
      setUserBalance({ ...setUserBalance(), balance: result.balance.placed });
    }
    setCurrentBonus({
      ...setCurrentBonus(),
      currentRound: setCurrentBonus().currentRound + 1,
    });
    eventManager.emit(EventTypes.UPDATE_FREE_ROUNDS_LEFT, setCurrentBonus().rounds - setCurrentBonus().currentRound);
    const freeSpins = result.bet.data?.bonuses?.find((e) => !e.isFreeBet) as UserBonus;
    if (!freeSpins) {
      setFreeRoundsTotalWin(setFreeRoundsTotalWin() + result.bet.result.winCoinAmount);
    }
    setBottomContainerTotalWin(setBottomContainerTotalWin() + result.bet.result.winCoinAmount);

    setBetResult(result);
    setLastSpinData({
      layout: [],
      reelPositions: getBetResult(setBetResult()).bet.result.reelPositions,
    });
  }
}
