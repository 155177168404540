import { Group, Layer } from '@pixi/layers';
import i18n from 'i18next';
import { Application, Container } from 'pixi.js';

import type { SlotId } from '../config/config';
import { EventTypes, ReelSet, UserBonus } from '../global.d';
import {
  setAutoSpinsLeft,
  setBonusPackAutoPlay,
  setBrokenGame,
  setCurrentReelSetId,
  setIsAutoSpins,
  setIsRevokeThrowingError,
  setIsSlotBusy,
  setLastSpinData,
  setReelSets,
  setStressful,
  setUserLastBetResult,
} from '../gql/cache';
import { Logic } from '../logic';
import { States } from '../logic/config';
import { getLayerOrderByName } from '../utils';

import { ModalService } from './ModalService';
import Backdrop from './backdrop/backdrop';
import Background from './background/background';
import { BigWinContainer } from './bigWinPresentation/bigWinContainer';
import { BonusRewards } from './bonusRewards/bonusRewards';
import BottomContainer from './bottomContainer/bottomContainer';
import BuyFeatureBtn from './buyFeature/buyFeatureBtn';
import { CardsContainer } from './cards/cardsContainer';
import { Collection } from './collection/collection';
import { PopupTypes, eventManager } from './config';
import AutoplayBtn from './controlButtons/autoplayBtn';
import BetBtn from './controlButtons/betBtn';
import { BonusRewardsBtn } from './controlButtons/bonusRewardsBtn';
import FreePacksCount from './controlButtons/freePacksCount';
import LibraryBtn from './controlButtons/libraryBtn';
import MasterPacksCount from './controlButtons/masterPacksCount';
import MenuBtn from './controlButtons/menuBtn';
import SoundBtn from './controlButtons/soundBtn';
import SpinBtn from './controlButtons/spinBtn';
import TurboSpinBtn from './controlButtons/turboSpinBtn';
import type { ISlotData } from './d';
import FadeArea from './fadeArea/fadeArea';
import GameView from './gameView/gameView';
import PhoenixAnticipation from './phoenixAnticipation/phoenixAnticipation';
import { PopupController } from './popups/PopupController';
import BuyFeaturePopup from './popups/buyFeaturePopup/buyFeaturePopup';
import BuyFeaturePopupConfirm from './popups/buyFeaturePopupConfirm/buyFeaturePopupConfirm';
import { FreeRoundsPopup } from './popups/freeRoundsPopup/freeRoundsPopup';
import { FreeRoundsEndPopup } from './popups/freeRoundsPopup/freeRoundsPopupEnd';
import { RewardsPopup } from './popups/rewardsPopup/rewardsPopup';
import SafeArea from './safeArea/safeArea';
import WinCountUpMessage from './winAnimations/winCountUpMessage';

class SlotMachine {
  public static initSlotMachine = (slotData: ISlotData): void => {
    SlotMachine.slotMachine = new SlotMachine(Logic.the.application, slotData);
  };

  public static the(): SlotMachine {
    return SlotMachine.slotMachine;
  }

  private static slotMachine: SlotMachine;

  private application: Application;

  private cardsContainer!: CardsContainer;

  public layer: Layer;

  public layersGroup: Group;

  public bottomContainer!: BottomContainer;

  public gameView!: GameView;

  public background!: Background;

  private constructor(application: Application, slotConfig: ISlotData) {
    this.application = application;
    this.application.stage.sortableChildren = true;
    this.layersGroup = new Group(1, (layer) => {
      layer.zOrder = getLayerOrderByName(layer.name);
    });
    this.layer = new Layer(this.layersGroup);
    this.application.stage.addChild(this.layer);
    this.initSlotMachineListeners();
    this.buildSlotMachine(slotConfig);
    ModalService.the.registerModal(this.application.stage);
  }

  private initSlotMachineListeners(): void {
    this.application.renderer.once(EventTypes.POST_RENDER, () => {
      if (!setBrokenGame()) eventManager.emit(EventTypes.GAME_READY);
    });
    eventManager.addListener(EventTypes.THROW_ERROR, SlotMachine.handleError);
    eventManager.addListener(EventTypes.ROLLBACK_STATE, () => this.rollbackState());
  }
  private buildSlotMachine(slotConfig: ISlotData): void {
    const isLastBetPresent = setUserLastBetResult().id;
    setReelSets(slotConfig.reels);
    const startPosition = isLastBetPresent
      ? setUserLastBetResult().result.reelPositions
      : slotConfig.settings.startPosition;
    setLastSpinData({
      layout: [],
      reelPositions: startPosition,
    });

    const reelSet = isLastBetPresent
      ? (slotConfig.reels.find((reelSet) => reelSet.id === setUserLastBetResult().reelSetId)! as ReelSet)
      : (slotConfig.reels[0] as ReelSet);
    this.background = new Background();
    this.background.name = 'Background';
    this.background.parentGroup = this.layersGroup;
    this.cardsContainer = this.getCardsContainer(reelSet.layout, startPosition);
    const rewardsPopup = new RewardsPopup();
    const bigWinContainer = new BigWinContainer();
    bigWinContainer.name = 'BigWinContainer';
    bigWinContainer.parentGroup = this.layersGroup;
    this.gameView = new GameView({
      cardsContainer: this.cardsContainer,
      rewardsPopup,
      bigWinContainer,
      winCountUpMessage: new WinCountUpMessage(),
    });
    const menuBtn = new MenuBtn();
    const soundBtn = new SoundBtn();
    const turboSpinBtn = new TurboSpinBtn();
    const spinBtn = new SpinBtn();
    const masterPacksCount = new MasterPacksCount();
    const freePacksCount = new FreePacksCount();
    const betBtn = new BetBtn();
    const libraryBtn = new LibraryBtn((newCards) =>
      ModalService.the.open(new Collection(newCards), { title: 'collection' }),
    );
    const bonusRewardsBtn = new BonusRewardsBtn((newRewards) =>
      ModalService.the.open(new BonusRewards(newRewards), { title: 'appearance' }),
    );
    const autoplayBtn = new AutoplayBtn();
    const safeArea = new SafeArea();
    const phoenixAnticipation = new PhoenixAnticipation();
    const freeRoundsPopup = new FreeRoundsPopup();
    const freeRoundsEndPopup = new FreeRoundsEndPopup();

    safeArea.name = 'SafeArea';
    safeArea.parentGroup = this.layersGroup;

    setCurrentReelSetId(reelSet.id);

    this.gameView.addChild(new BuyFeatureBtn());
    this.gameView.addChild(this.buildBuyFeaturePopupsContainer());

    PopupController.the.registerPopup(PopupTypes.REWARD_POPUP, rewardsPopup);
    PopupController.the.registerPopup(PopupTypes.FREE_ROUNDS, freeRoundsPopup);
    PopupController.the.registerPopup(PopupTypes.FREE_ROUNDS_END, freeRoundsEndPopup);

    safeArea.addChild(this.gameView);
    const backdrop = new Backdrop(EventTypes.OPEN_POPUP_BG, EventTypes.CLOSE_POPUP_BG);
    backdrop.name = 'Backdrop';
    backdrop.parentGroup = this.layersGroup;
    this.bottomContainer = new BottomContainer();
    this.application.stage.addChild(
      this.background,
      backdrop,
      safeArea,
      this.bottomContainer,
      new FadeArea(),
      menuBtn,
      soundBtn,
      turboSpinBtn,
      spinBtn,
      freePacksCount,
      masterPacksCount,
      betBtn,
      libraryBtn,
      bonusRewardsBtn,
      autoplayBtn,
      phoenixAnticipation,
      freeRoundsPopup,
      freeRoundsEndPopup,
    );
  }

  private buildBuyFeaturePopupsContainer(): Container {
    const container = new Container();
    const buyFeaturePopup = new BuyFeaturePopup();
    const buyFeaturePopupConfirm = new BuyFeaturePopupConfirm();
    PopupController.the.registerPopup(PopupTypes.BUY_FEATURE, buyFeaturePopup);
    PopupController.the.registerPopup(PopupTypes.BUY_FEATURE_CONFIRMATION, buyFeaturePopupConfirm);
    container.addChild(buyFeaturePopup, buyFeaturePopupConfirm);

    return container;
  }

  private getCardsContainer(reelSetLayout: SlotId[][], startPosition: number[]) {
    return new CardsContainer(reelSetLayout, startPosition);
  }

  private static handleError(): void {
    if (!setIsRevokeThrowingError()) {
      SlotMachine.the().rollbackState();
      setStressful({
        show: true,
        type: 'network',
        message: i18n.t('errors.UNKNOWN.UNKNOWN'),
      });
    }
  }

  public rollbackState(): void {
    if (setIsAutoSpins()) {
      setAutoSpinsLeft(0);
      setIsAutoSpins(false);
      setBonusPackAutoPlay(false);
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    }
    setIsSlotBusy(false);
    if (Logic.the.state.name !== States.IDLE) {
      Logic.the.changeState(States.IDLE);
    }
  }

  public onBrokenGame(bonus: UserBonus): void {
    eventManager.emit(EventTypes.BROKEN_GAME, bonus);
    eventManager.emit(EventTypes.GAME_READY);
  }
}

export default SlotMachine;
