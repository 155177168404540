import { Spine } from 'pixi-spine';
import { Loader, Sprite, Texture } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { CardAbilitiesPattern, ISongs } from '../../config';
import { EventTypes, GameMode, ICardFeaturesData, UserBonus, bonusesId } from '../../global.d';
import {
  setBetAmount,
  setBetResult,
  setFreePacksBonus,
  setFreePacksCount,
  setFreePacksCountArray,
  setIsTurboSpin,
  setMasterPacksBonus,
  setMasterPacksCount,
  setMasterPacksCountArray,
} from '../../gql/cache';
import { Logic } from '../../logic';
import { States } from '../../logic/config';
import { getBetResult, isRegularMode, normalizeCoins } from '../../utils';
import type { IEntryAnimation } from '../animations/d';
import { ViewContainer } from '../components/ViewContainer';
import { REELS_AMOUNT, eventManager } from '../config';
import { FreePackAnimations, ICardAbility, MasterPackAnimations, PackAnimations } from '../d';
import { TimeActions } from '../tooltips/timeActions';
import { Tooltip } from '../tooltips/tooltip';
import { Tooltips } from '../tooltips/tooltips.model';

import { Card } from './card';

export class CardsContainer extends ViewContainer {
  public pack: Spine;
  public newPack: Spine;
  public openedCards: number[] = [];
  private hasMasterPacks = false;
  private hasFreePacks = false;
  private gameMode: GameMode;
  private tooltip = this.getTooltip();

  constructor(_a: unknown, _b: unknown) {
    super();
    this.pack = new Spine(Loader.shared.resources['packOpen']!.spineData!);
    this.newPack = new Spine(Loader.shared.resources['packOpen']!.spineData!);
    this.newPack.visible = false;
    this.gameMode = GameMode.BASE_GAME;

    this.addChild(this.pack, this.newPack, this.tooltip);

    TimeActions.the.executeBeforeFlipCard(20, 5, () => this.tooltip.show());

    eventManager.addListener(EventTypes.CARD_IS_OPENED, this.checkOpenedCards.bind(this));
    eventManager.addListener(EventTypes.DISPLAY_INITIAL_PACK, () => {
      this.handleOpenPack();
    });
    eventManager.addListener(EventTypes.WIN_MASTER_PACKS, (changeWithoutAnimation: boolean) => {
      const betResult = getBetResult(setBetResult());
      const masterPacksBonus = betResult.bet.data.bonuses.find(
        (bonus) => bonus.bonusId === bonusesId[GameMode.MASTER_PACKS],
      );
      setMasterPacksBonus({
        ...(masterPacksBonus as UserBonus),
        isActive: false,
      });
      let cardsWithMasterAbility;
      if (setMasterPacksCountArray().length) {
        cardsWithMasterAbility = setMasterPacksCountArray();
      } else {
        cardsWithMasterAbility = betResult.bet.data.features.base.filter(
          (card: ICardFeaturesData) =>
            card.abilities?.length &&
            card.abilities.find((ab: ICardAbility) => ab.pattern === CardAbilitiesPattern.NM && ab.value),
        );
        setMasterPacksCountArray(cardsWithMasterAbility);
      }
      const masterPacksCard = cardsWithMasterAbility.splice(0, 1)[0];
      const ability: ICardAbility = masterPacksCard!.abilities.find(
        (e: ICardAbility) => e.pattern === CardAbilitiesPattern.NM,
      );
      setMasterPacksCount(setMasterPacksCount() + ability.value);
      if (!this.hasMasterPacks) {
        this.hasMasterPacks = true;
        if (Logic.the.controller.gameMode !== GameMode.FREE_PACKS || !setFreePacksCount()) {
          if (changeWithoutAnimation) {
            this.newPack.state.setAnimation(0, MasterPackAnimations.MasterPackIdle, false);
          } else {
            this.newPack.state.setAnimation(0, MasterPackAnimations.MasterPackIn, false);
          }
        }
        eventManager.emit(EventTypes.ADD_WIN_MASTER_PACKS);
      } else {
        eventManager.emit(EventTypes.UPDATE_WIN_MASTER_PACKS);
      }
    });
    eventManager.addListener(EventTypes.WIN_FREE_PACKS, (changeWithoutAnimation: boolean) => {
      const betResult = getBetResult(setBetResult());
      const freePacksBonus = betResult.bet.data.bonuses.find(
        (bonus) => bonus.bonusId === bonusesId[GameMode.FREE_PACKS],
      );
      setFreePacksBonus({
        ...(freePacksBonus as UserBonus),
        isActive: false,
      });
      let cardsWithFreeAbility;
      if (setFreePacksCountArray().length) {
        cardsWithFreeAbility = setFreePacksCountArray();
      } else {
        cardsWithFreeAbility = betResult.bet.data.features.base.filter(
          (card: ICardFeaturesData) =>
            card.abilities?.length &&
            card.abilities.find((ab: ICardAbility) => ab.pattern === CardAbilitiesPattern.Np && ab.value),
        );
        setFreePacksCountArray(cardsWithFreeAbility);
      }
      const freePacksCount = cardsWithFreeAbility.splice(0, 1)[0];
      const ability: ICardAbility = freePacksCount!.abilities.find(
        (e: ICardAbility) => e.pattern === CardAbilitiesPattern.Np,
      );
      setFreePacksCount(setFreePacksCount() + ability.value);
      if (!this.hasFreePacks) {
        this.hasFreePacks = true;
        if (changeWithoutAnimation) {
          this.newPack.state.setAnimation(0, FreePackAnimations.FreePackIdle, false);
        } else {
          this.newPack.state.setAnimation(0, FreePackAnimations.FeePackIn, false);
        }
        eventManager.emit(EventTypes.ADD_WIN_FREE_PACKS);
      } else {
        eventManager.emit(EventTypes.UPDATE_WIN_FREE_PACKS);
      }
    });

    eventManager.addListener(EventTypes.BASE_GAME_PACKS, () => {
      this.newPack.state.setAnimation(0, PackAnimations.NormalPackIn, false);
    });

    this.pack.state.addListener({
      start: (entry: IEntryAnimation) => {
        if (entry.animation.name.startsWith('pack_open')) {
          this.newPack.visible = false;
          this.initCards();
        }
      },
      complete: (entry: IEntryAnimation) => {
        if (entry.animation.name.startsWith('pack_open')) {
          eventManager.emit(EventTypes.CARDS_INITIALIZED);
          Logic.the.changeState(States.BEFORE_WIN);
          this.newPack.visible = true;
          AudioApi.play({ type: ISongs.BottomCard });

          if (Logic.the.controller.gameMode === GameMode.MASTER_PACKS && setMasterPacksCount()) {
            this.newPack.state.setAnimation(0, MasterPackAnimations.MasterPackIn, false);
            if (!setFreePacksCount()) {
              this.hasFreePacks = false;
            }
          } else if (Logic.the.controller.gameMode === GameMode.FREE_PACKS) {
            if (setFreePacksCount()) {
              this.newPack.state.setAnimation(0, FreePackAnimations.FeePackIn, false);
            } else if (setMasterPacksCount()) {
              this.newPack.state.setAnimation(0, MasterPackAnimations.MasterPackIn, false);
              this.hasFreePacks = false;
              setFreePacksCountArray([]);
            } else {
              this.newPack.state.setAnimation(0, PackAnimations.NormalPackIn, false);
              this.hasMasterPacks = false;
              this.hasFreePacks = false;
              setMasterPacksCountArray([]);
              setFreePacksCountArray([]);
            }
          } else {
            this.newPack.state.setAnimation(0, PackAnimations.NormalPackIn, false);
            this.hasMasterPacks = false;
            this.hasFreePacks = false;
            setMasterPacksCountArray([]);
            setFreePacksCountArray([]);
          }
        }
      },
    });

    if (Logic.the.controller.gameMode === GameMode.FREE_PACKS || setFreePacksCount()) {
      this.pack.state.setAnimation(0, FreePackAnimations.FeePackIn, false);
    } else if (Logic.the.controller.gameMode === GameMode.MASTER_PACKS || setMasterPacksCount()) {
      this.pack.state.setAnimation(0, MasterPackAnimations.MasterPackIn, false);
    } else {
      this.pack.state.setAnimation(0, PackAnimations.NormalPackIn, false);
    }
  }

  public initCards(): void {
    const result = getBetResult(setBetResult());
    const baseCards = result.bet.data.features.base;

    baseCards.forEach((item, index) => {
      const cardPlaceholder = this.pack.skeleton.findSlot(`place_holder${index + 1}`).currentSprite as Sprite;
      if (!cardPlaceholder.children.length) {
        const card = new Card(index, item.name, item.class, item.level, item.abilities, item.price);
        cardPlaceholder.texture = Texture.EMPTY;
        cardPlaceholder.addChild(card);
      } else {
        const placeholderCard = cardPlaceholder.children[0] as Card;
        if (placeholderCard) {
          placeholderCard.resetCard(index, item.name, item.class, item.level, item.abilities, item.price);
        }
      }
    });
  }

  private handleOpenPack(): void {
    this.openedCards = [];
    setTimeout(
      () => {
        const isTurboSpin = setIsTurboSpin();
        this.pack.state.timeScale = isTurboSpin ? 3 : 1.5;
        const animationName = this.getPackAnimationByRewards();
        this.pack.state.setAnimation(0, animationName, false);
      },
      setIsTurboSpin() ? 250 : 500,
    );
  }

  public checkOpenedCards(id: number): void {
    this.tooltip.hide();
    this.openedCards.push(id);
    if (this.openedCards.length === REELS_AMOUNT) {
      Logic.the.changeState(States.WIN_PRESENTATION);
    }
  }

  override onModeChange(_settings: { mode: GameMode }): void {
    this.gameMode = _settings.mode;
  }

  public getPackAnimationByRewards(): string {
    let animationType: 'min' | 'max' | 'normal' = 'min';
    let animationName = '';
    const randomNumber = Math.random();
    const betResult = getBetResult(setBetResult());
    const bonuses = betResult.bet.data.bonuses;
    const { winCoinAmount } = betResult.bet.result;
    const betAmount = normalizeCoins(setBetAmount());
    const multiplier = normalizeCoins(winCoinAmount) / betAmount;
    const freePacksBonus = bonuses.find((bonus) => bonus.bonusId === bonusesId[GameMode.FREE_PACKS]);

    if (multiplier < 5) {
      animationType = 'min';
    } else if (multiplier < 10 || freePacksBonus) {
      animationType = randomNumber <= 0.5 ? 'min' : 'normal';
    } else {
      if (randomNumber <= 0.4) {
        animationType = 'min';
      } else if (randomNumber > 0.4 && randomNumber <= 0.7) {
        animationType = 'normal';
      } else {
        animationType = 'max';
      }
    }

    if (animationType === 'max') {
      if (Logic.the.controller.gameMode === GameMode.MASTER_PACKS)
        animationName = MasterPackAnimations.MasterPackOpenMax;
      if (Logic.the.controller.gameMode === GameMode.FREE_PACKS) animationName = FreePackAnimations.FreePackOpenMax;
      if (isRegularMode(Logic.the.controller.gameMode)) animationName = PackAnimations.NormalPackOpenMax;
      AudioApi.play({ type: setIsTurboSpin() ? ISongs.Pack_Open_Max_OpenFast : ISongs.Pack_Open_Max });
    } else if (animationType === 'min') {
      if (Logic.the.controller.gameMode === GameMode.MASTER_PACKS)
        animationName = MasterPackAnimations.MasterPackOpenMin;
      if (Logic.the.controller.gameMode === GameMode.FREE_PACKS) animationName = FreePackAnimations.FreePackOpenMin;
      if (isRegularMode(Logic.the.controller.gameMode)) animationName = PackAnimations.NormalPackOpenMin;
      AudioApi.play({ type: setIsTurboSpin() ? ISongs.Pack_Open_Min_OpenFast : ISongs.Pack_Open_Min });
    } else {
      if (Logic.the.controller.gameMode === GameMode.MASTER_PACKS) animationName = MasterPackAnimations.MasterPackOpen;
      if (Logic.the.controller.gameMode === GameMode.FREE_PACKS) animationName = FreePackAnimations.FreePackOpen;
      if (isRegularMode(Logic.the.controller.gameMode)) animationName = PackAnimations.NormalPackOpen;
      AudioApi.play({ type: setIsTurboSpin() ? ISongs.Pack_Open_Regular_OpenFast : ISongs.Pack_Open_Regular });
    }
    return animationName;
  }

  private getTooltip(): Tooltip {
    const tooltip = new Tooltip(Tooltips.cards, 'tooltipCards');

    tooltip.position.set(-tooltip.width / 2, -tooltip.height - 250);

    return tooltip;
  }
}

export default CardsContainer;
