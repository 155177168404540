import { useQuery } from '@apollo/client';
import _ from 'lodash';
import { isMobile } from 'mobile-device-detect';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiChevronLeft, BiChevronRight, BiFirstPage, BiLastPage } from 'react-icons/bi';

import { Button } from '@phoenix7dev/shared-components';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { config } from '../../config';
import { GameMode, bonusesId } from '../../global.d';
import { setCurrency, setSlotConfig } from '../../gql/cache';
import type { ISlotHistory, ISlotHistoryData } from '../../gql/d';
import { slotHistoryGql } from '../../gql/query';
import { normalizeCoins, showCurrency } from '../../utils';
import { countCoins } from '../../utils/helper';

import styles from './history.module.scss';

const formatHistory = (historyData: ISlotHistoryData['edges']) => {
  const history = _.map(
    _.map(historyData, (elem) => ({
      ...elem.node,
      cursor: elem.cursor,
    })),
    (elem) => {
      const date = _.get(elem, 'createdAt', Date.now());
      const baseCoinAmount = _.get(elem, 'coinAmount', 1);
      const coinAmount =
        elem.userBonus?.bonusId !== bonusesId[GameMode.FREE_ROUND_BONUS] && elem.userBonus
          ? _.get(elem, 'userBonus.bonus.coinAmount', 1) * baseCoinAmount
          : baseCoinAmount;
      const coins = countCoins({
        coinValue: _.get(elem, 'coinValue', 1),
        coinAmount,
        lines: setSlotConfig().lineSets[0]!.coinAmountMultiplier,
      });
      const bet = formatNumber({ currency: setCurrency(), value: coins, showCurrency: showCurrency(setCurrency()) });
      const win = formatNumber({
        currency: setCurrency(),
        value: normalizeCoins(elem.result.winCoinAmount),
        showCurrency: showCurrency(setCurrency()),
      });

      return {
        date,
        win,
        bet,
        gameId: elem.id,
        cursor: elem.cursor,
        userBonusId: elem.userBonus?.bonusId || '',
      };
    },
  );

  return _.reverse(history);
};

const renderKey = (item: ISlotHistory, key: string) => {
  if (key === 'date') {
    return new Date(item[key as keyof ISlotHistory] as string).toLocaleString('en-US', { hour12: true });
  }

  if (key === 'bet') {
    return item[key as string];
  }

  return item[key as string];
};
const HistoryComponent: React.FC = () => {
  const { head, showBy } = config.historyTable;
  const { t } = useTranslation();
  const [pageAmount, setPageAmount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const cursorLastRef = useRef('');
  const cursorFirstRef = useRef('');

  const { data: historyData, fetchMore } = useQuery<
    { bets: ISlotHistoryData },
    {
      input: {
        first?: number;
        last?: number;
        before?: string;
        after?: string;
        filter?: { slotId?: string };
      };
    }
  >(slotHistoryGql, {
    variables: {
      input: { last: showBy, filter: { slotId: setSlotConfig().id } },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (historyData?.bets?.edges.length) {
      setPageAmount(Math.ceil(_.get(historyData, 'bets.pageInfo.count', 0) / showBy));
      cursorLastRef.current = historyData.bets.edges[historyData.bets.edges.length - 1]!.cursor;
      cursorFirstRef.current = historyData.bets.edges[0]!.cursor;
    }
  }, [historyData, showBy]);

  if (!historyData?.bets) {
    return (
      <div className={styles['history']}>
        <div className={styles['spinner']} />
      </div>
    );
  }

  const { pageInfo, edges } = historyData.bets;
  return (
    <div className={styles['history']}>
      <div className={styles['table']}>
        <table className={styles['historyTable']}>
          <thead>
            <tr>
              {Object.entries(head).map(([key, value]) => (
                <th key={key}>{t(value)}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {formatHistory(edges).map((item) => (
              <tr key={item.gameId}>
                {Object.keys(head).map((key) => (
                  <td key={key}>
                    <>
                      {renderKey(item, key)}
                      {key === 'bet' &&
                        item.userBonusId &&
                        item.userBonusId === bonusesId[GameMode.FREE_ROUND_BONUS] && (
                          <div className={styles['freeBet']}>{t('freeRound')}</div>
                        )}
                    </>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {pageInfo.count > showBy && (
          <div className={styles['pagination']}>
            <Button
              disabled={currentPage === 1}
              className={styles['pagination__btn'] as string}
              key="first"
              onClick={() => {
                fetchMore({
                  variables: {
                    input: {
                      last: showBy,
                      filter: { slotId: setSlotConfig().id },
                    },
                  },
                }).then(() => {
                  setCurrentPage(1);
                });
              }}
            >
              <BiFirstPage />
            </Button>
            <Button
              disabled={currentPage === 1}
              className={`${styles['pagination__btn']} ${!isMobile ? styles['hover'] : styles['active']}`}
              onClick={() => {
                fetchMore({
                  variables: {
                    input: {
                      first: showBy,
                      after: cursorLastRef.current,
                      filter: { slotId: setSlotConfig().id },
                    },
                  },
                }).then(() => {
                  setCurrentPage(Math.max(1, currentPage - 1));
                });
              }}
            >
              <BiChevronLeft />
            </Button>
            <Button
              disabled={currentPage === pageAmount}
              className={`${styles['pagination__btn']} ${!isMobile ? styles['hover'] : styles['active']}`}
              onClick={() => {
                fetchMore({
                  variables: {
                    input: {
                      last: showBy,
                      before: cursorFirstRef.current,
                      filter: { slotId: setSlotConfig().id },
                    },
                  },
                }).then(() => {
                  setCurrentPage(Math.min(pageAmount, currentPage + 1));
                });
              }}
            >
              <BiChevronRight />
            </Button>
            <Button
              disabled={currentPage === pageAmount}
              className={styles['pagination__btn'] as string}
              onClick={() => {
                fetchMore({
                  variables: {
                    input: {
                      first: ((pageInfo.count - 1) % showBy) + 1,
                      filter: { slotId: setSlotConfig().id },
                    },
                  },
                }).then(() => {
                  setCurrentPage(pageAmount);
                });
              }}
            >
              <BiLastPage />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(HistoryComponent);
