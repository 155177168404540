import { Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';
import { isMobile } from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { setAutoSpinsLeft, setCardsOpeningInprogress, setIsAutoSpins, setIsPopupOpened } from '../../gql/cache';
import { Logic } from '../../logic';
import { calcPercentage, canPressSpin, isRegularMode } from '../../utils';
import { eventManager } from '../config';
import { TimeActions } from '../tooltips/timeActions';
import { Tooltip, tooltipsStore } from '../tooltips/tooltip';
import { Tooltips } from '../tooltips/tooltips.model';

import { ControlButton } from '.';

enum ButtonType {
  flipPack = 'flip-pack',
  flipPackFree = 'flip-pack_free',
  flipPackMaster = 'flip-pack_master',
  buyPack = 'buy-pack',
  buyPackFree = 'buy-pack_free',
  buyPackMaster = 'buy-pack_master',
  autoplayStop = 'autoplay-stop',
}

class SpinBtn extends ControlButton {
  private count: PIXI.Text;

  private isAutoSpins: boolean;

  private isSlotBusy: boolean;

  private isFreeSpinsWin: boolean;

  private animation: Spine;
  private tooltipFlip = this.getTooltip('tooltipFlip');
  private tooltipPack = this.getTooltip('tooltipBuyCards');
  constructor() {
    super('buy-pack');
    this.interactive = !this.isDisabled;
    this.isAutoSpins = false;
    this.isSlotBusy = false;
    this.isFreeSpinsWin = false;
    this.count = this.initCount();
    this.animation = this.initAnimation();
    this.sortableChildren = true;
    this.animation.interactive = false;

    this.setAnimationByGameMode(Logic.the.controller.gameMode);

    this.btn.addChild(this.tooltipFlip, this.tooltipPack, this.count);

    TimeActions.the.executeBeforeFlipCard(20, 5, () => this.tooltipFlip.show());
    TimeActions.the.executeBeforeBuyPack(20, 15, () => this.tooltipPack.show());
    TimeActions.the.triggerBuyPack();

    this.addChild(this.animation);
    this.on('click', () => this.handleClick());
    this.on('touchstart', () => this.handleClick());
    this.initSubscriptions();
  }

  public getTooltip(message: string): Tooltip {
    const tooltip = new Tooltip(Tooltips.spin, message);
    const ratio = isMobile.any ? 0.7 : 1;
    tooltip.scale.set(ratio);

    tooltip.position.set(-tooltip.width / 2, -tooltip.height - 60);

    return tooltip;
  }

  private initAnimation(): Spine {
    const animation = new Spine(PIXI.Loader.shared.resources!['uiAnimation']!.spineData!);
    animation.name = 'cardBacklightAnimation';
    animation.zIndex = -1;

    return animation;
  }

  private setAnimationByGameMode(gameMode: GameMode): void {
    switch (gameMode) {
      case GameMode.FREE_PACKS:
        this.animation.visible = true;
        this.animation.state.setAnimation(0, 'buy_pack_button_appear_blue', false);
        if (!setIsAutoSpins()) {
          this.animation.state.addAnimation(0, 'buy_pack_button_idle_blue', true, 0);
        }
        break;
      case GameMode.MASTER_PACKS:
        this.animation.visible = true;
        this.animation.state.setAnimation(0, 'buy_pack_button_appear_yellow', false);
        if (!setIsAutoSpins()) {
          this.animation.state.addAnimation(0, 'buy_pack_button_idle_yellow', true, 0);
        }
        break;
      case GameMode.BASE_GAME:
      case GameMode.FREE_ROUND_BONUS:
        if (Logic.the.controller.gameMode === GameMode.MASTER_PACKS) {
          this.animation.state.setAnimation(0, 'buy_pack_button_disappear_yellow', false);
        }
        if (Logic.the.controller.gameMode === GameMode.FREE_PACKS) {
          this.animation.state.setAnimation(0, 'buy_pack_button_disappear_blue', false);
        }
        this.animation.visible = false;
        break;
      default:
        // this.animation.state.setAnimation(0, 'buy_pack_button_disappear_blue', false);
        // this.animation.state.addAnimation(0, 'buy_pack_button_idle__yellow', true, 0);
        break;
    }
  }

  protected override onModeChange(_settings: { mode: GameMode }): void {
    this.setAnimationByGameMode(_settings.mode);
    this.updateIntentByCondition(_settings.mode);
    this.handleDisable();
  }

  private initSubscriptions = (): void => {
    eventManager.on(EventTypes.SET_IS_AUTO_SPINS, (isAutoSpins: boolean) => {
      this.isAutoSpins = isAutoSpins;
      this.count.visible = isRegularMode(Logic.the.controller.gameMode) && isAutoSpins;
      this.updateIntentByCondition(Logic.the.controller.gameMode);
      this.handleDisable();
      this.setAnimationByGameMode(GameMode.BASE_GAME);
    });
    eventManager.on(EventTypes.SET_AUTO_SPINS_LEFT, (autoSpinsLeft: string) => {
      TimeActions.the.incrementFlipBuyPack();
      this.count.text = autoSpinsLeft;
      this.count.visible = isRegularMode(Logic.the.controller.gameMode) && !!autoSpinsLeft && this.isAutoSpins;
      this.setScaleCountText();
    });
    eventManager.on(EventTypes.SET_IS_SLOT_IS_BUSY, (isSlotBusy: boolean) => {
      this.isSlotBusy = isSlotBusy;
      this.updateIntentByCondition(Logic.the.controller.gameMode);
      this.handleDisable();
    });
    eventManager.on(EventTypes.OPEN_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.CLOSE_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_FREESPINS_WIN, (isFreeSpinWin: boolean) => {
      this.isFreeSpinsWin = isFreeSpinWin;
      this.handleDisable();
    });
    eventManager.on(EventTypes.CHANGE_STATE, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.UPDATE_SPIN_BUTTON_INTENT, () => {
      this.updateIntentByCondition(Logic.the.controller.gameMode);
    });
  };

  private initCount = (): PIXI.Text => {
    const count = new PIXI.Text('', {
      fontSize: 33,
      whiteSpace: 'normal',
      fontWeight: '700',
      align: 'center',
    });
    count.visible = false;
    count.anchor.set(0.5);
    count.y = 0;
    count.x = 0;

    return count;
  };

  private updateIntentByCondition(gameMode: GameMode): void {
    tooltipsStore.hideAll();
    this.count.visible = isRegularMode(Logic.the.controller.gameMode) && this.isAutoSpins && !!setAutoSpinsLeft();
    const isSlotBusyOrAutoSpins = Logic.the.isReadyForFlip || this.isAutoSpins;
    const intentConditions: Record<ButtonType, () => boolean> = {
      [ButtonType.autoplayStop]: () => {
        this.animation.alpha = 1;
        return this.isAutoSpins;
      },
      [ButtonType.buyPack]: () => {
        this.animation.alpha = 1;
        return (!isSlotBusyOrAutoSpins || !setCardsOpeningInprogress()) && isRegularMode(gameMode);
      },
      [ButtonType.flipPack]: () => {
        this.animation.alpha = 0;
        return isSlotBusyOrAutoSpins && isRegularMode(gameMode);
      },
      [ButtonType.buyPackFree]: () => {
        this.animation.alpha = 1;
        return (!isSlotBusyOrAutoSpins || !setCardsOpeningInprogress()) && gameMode === GameMode.FREE_PACKS;
      },
      [ButtonType.flipPackFree]: () => {
        this.animation.alpha = 0;
        return isSlotBusyOrAutoSpins && gameMode === GameMode.FREE_PACKS;
      },
      [ButtonType.buyPackMaster]: () => {
        this.animation.alpha = 1;
        return (!isSlotBusyOrAutoSpins || !setCardsOpeningInprogress()) && gameMode === GameMode.MASTER_PACKS;
      },
      [ButtonType.flipPackMaster]: () => {
        this.animation.alpha = 0;
        return isSlotBusyOrAutoSpins && gameMode === GameMode.MASTER_PACKS;
      },
    };
    const intent = (Object.entries(intentConditions).find(([_, condition]) => condition()) ?? [''])[0];

    if ([ButtonType.flipPackFree, ButtonType.flipPackMaster, ButtonType.flipPack].includes(intent as ButtonType)) {
      TimeActions.the.triggerFlipAllCard();
    }
    if ([ButtonType.buyPackFree, ButtonType.buyPackMaster, ButtonType.buyPack].includes(intent as ButtonType)) {
      TimeActions.the.triggerBuyPack();
    }

    this.updateIntent(intent);
  }

  private handleDisable = (): void => {
    const isDisable = !canPressSpin({
      gameMode: Logic.the.controller.gameMode,
      slotState: Logic.the.state.name,
      isSlotBusy: this.isSlotBusy,
      isPopupOpened: setIsPopupOpened(),
    });
    if (isDisable) {
      TimeActions.the.resetTimers();
    }

    this.setDisable(isDisable);
  };

  private handleClick = (): void => {
    if (this.isDisabled) return;

    eventManager.emit(EventTypes.TOGGLE_SPIN);
    tooltipsStore.hideAll();

    if (this.intent !== ButtonType.autoplayStop) {
      TimeActions.the.incrementFlipBuyPack();
    }
  };

  private setScaleCountText = () => {
    this.animation.scale.set(this.btn.scale.x, this.btn.scale.y);
  };

  public override handlePosition(): void {
    this.setScaleCountText();
    let x = this.isLeftHandMode
      ? calcPercentage(this.applicationSize.width, 11)
      : calcPercentage(this.applicationSize.width, 89);
    let y = calcPercentage(this.applicationSize.height, 91);
    this.setSize(calcPercentage(this.applicationSize.width, 8.2));

    if (this.isPortraitMode) {
      this.setSize(calcPercentage(this.underGameContainerSpaceHeight, 45));
      x = calcPercentage(this.applicationSize.width, 50);
      y = this.gameContainerBottomPosition + calcPercentage(this.underGameContainerSpaceHeight, 57);
    }

    if (this.isLandscapeMode) {
      this.setSize(calcPercentage(this.applicationSize.height, 19));
      x = this.isLeftHandMode
        ? calcPercentage(this.applicationSize.width, 9)
        : calcPercentage(this.applicationSize.width, 91);
      y = calcPercentage(this.applicationSize.height, 50);
    }

    this.x = x;
    this.y = y;
  }
}

export default SpinBtn;
