import type { ITextStyle } from 'pixi.js';

const font = 'NotoSans-SemiCondensedBold';

export const titleStyle = {
  fontSize: 80,
  fontFamily: font,
  whiteSpace: 'normal',
  leading: '',
  align: 'center',
  fill: ['#f0fb4c', '#e8a329', '#936419', '#b48836'],
  fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
  fillGradientType: 0,
  stroke: '#573083',
  strokeThickness: 3,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 4,
  dropShadowDistance: 2,
  fontWeight: 'bolder',
  lineJoin: 'round',
};

export const mobileTitleStyle = {
  fontSize: 85,
  fontFamily: font,
  whiteSpace: 'normal',
  leading: '',
  align: 'center',
  fill: ['#f0fb4c', '#e8a329', '#936419', '#b48836'],
  fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
  fillGradientType: 0,
  stroke: '#573083',
  strokeThickness: 6,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 4,
  dropShadowDistance: 2,
  fontWeight: 'bolder',
  lineJoin: 'round',
};

export const subtitleStyle = {
  fontSize: 80,
  fontFamily: font,
  whiteSpace: 'normal',
  leading: '',
  align: 'center',
  fill: ['#f0fb4c', '#e8a329', '#936419', '#b48836'],
  fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
  fillGradientType: 0,
  stroke: '#573083',
  strokeThickness: 2,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 4,
  wordWrapWidth: 60,
  dropShadowDistance: 2,
  lineJoin: 'round',
};

export const mobileSubtitleStyle = {
  fontSize: 80,
  fontFamily: font,
  whiteSpace: 'normal',
  leading: '',
  align: 'center',
  fill: ['#f0fb4c', '#e8a329', '#936419', '#b48836'],
  fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
  fillGradientType: 0,
  stroke: '#573083',
  strokeThickness: 6,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 4,
  wordWrap: true,
  wordWrapWidth: 100,
  dropShadowDistance: 2,
  lineJoin: 'round',
  miterLimit: 10,
};

export const additionalStyle = {
  fontSize: 40,
  fontFamily: font,
  whiteSpace: 'normal',
  leading: '',
  align: 'center',
  fill: ['#ff8800', '#ff0000', '#880000'],
  fillGradientStops: [0.25, 0.5, 0.75],
  fillGradientType: 0,
  stroke: '#FFFFFF',
  strokeThickness: 3,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 4,
  dropShadowDistance: 2,
  lineJoin: 'round',
};

export const winTitleStyle = {
  dropShadowDistance: '',
  fill: [
    // '#f08418',
    '#9f6518',
    '#ffeb19',
    '#FBC41C',
    '#F5FF3C',
    '#f08418',
  ],
  fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 180,
  whiteSpace: 'pre-line',
  stroke: '#573083',
  strokeThickness: 6,
  fontWeight: 500,
};

export const mobileWinTitleStyle = {
  dropShadowDistance: '',
  fill: [
    // '#f08418',
    '#9f6518',
    '#ffeb19',
    '#FBC41C',
    '#F5FF3C',
    '#f08418',
  ],
  fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 140,
  fontWeight: 500,
  whiteSpace: 'pre-line',
  stroke: '#573083',
  strokeThickness: 4,
};

export const winSubtitleStyle = {
  fontSize: 180,
  fontFamily: font,
  whiteSpace: 'normal',
  leading: '',
  align: 'center',
  fill: [
    // '#f08418',
    '#9f6518',
    '#ffeb19',
    '#FBC41C',
    '#F5FF3C',
    '#f08418',
  ],
  fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
  fillGradientType: 0,
  fontWeight: 'bold',
  lineJoin: 'round',
  stroke: '#573083',
  strokeThickness: 6,
};

export const mobileWinSubtitleStyle = {
  fontSize: 140,
  fontFamily: font,
  whiteSpace: 'normal',
  leading: '',
  align: 'center',
  fill: [
    // '#f08418',
    '#9f6518',
    '#ffeb19',
    '#FBC41C',
    '#F5FF3C',
    '#f08418',
  ],
  fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
  fontWeight: 'bold',
  lineJoin: 'round',
  stroke: '#573083',
  strokeThickness: 6,
};

export const btnStyle = {
  fontSize: 60,
  fontFamily: font,
  fill: '0xffffff',
  fillGradientStops: [0.25, 0.5, 0.75],
  fillGradientType: 0,
  dropShadow: true,
};

export const mobileBtnStyle = {
  fontSize: 80,
  fontFamily: font,
  fill: '0xffffff',
  fillGradientStops: [0.25, 0.5, 0.75],
  fillGradientType: 0,
  dropShadow: true,
};

export const layoutStyles: Partial<ITextStyle> = {
  fontFamily: 'NotoSans-SemiCondensedBold',
  align: 'center',
  fontSize: 26,
  fontWeight: '700',
  fill: '#FFFFFF',
};

export const LAYOUT_OPTIONS = {
  width: 430,
  height: 295,
  border: 2,
  borderRadius: 5,
  margin: 20,
  padding: 20,
  borderColor: 0xf6e87e,
  buttonColor: 0xffffff,
  bgColor: 0x000000,
  textStyle: layoutStyles,
  traceIdTextStyle: { ...layoutStyles, fontSize: 16, fill: '#ccc' },
  traceIdOpacity: 0.3,
};

export const freeRoundsTextStyles: Partial<ITextStyle> = {
  align: 'center',
  fontFamily: font,
  fontSize: 20,
  fontWeight: 'bold',
  fill: '#ffffff',
};

export const buttonTextStyle: Partial<ITextStyle> = {
  align: 'center',
  fontFamily: font,
  fontSize: 20,
  fontWeight: 'bold',
};
