import { EventTypes, GameMode, ModalOpeningTypes } from '../../global.d';
import {
  setIsModalOpeningInProgress,
  setIsOpenAutoplayModal,
  setIsOpenBetSettingsModal,
  setIsOpenMenuModal,
  setIsPopupOpened,
} from '../../gql/cache';
import { Logic } from '../../logic';
import { States } from '../../logic/config';
import { calcPercentage, isRegularMode } from '../../utils';
import { eventManager } from '../config';

import { ControlButton } from './index';

class BetBtn extends ControlButton {
  private isAutoSpins: boolean;

  private isSlotBusy: boolean;

  private isFreeSpinsWin: boolean;

  private isFreeRoundBonus: boolean;

  constructor() {
    super('bet-settings');
    this.interactive = !this.isDisabled;
    this.isAutoSpins = false;
    this.isSlotBusy = false;
    this.isFreeSpinsWin = false;
    this.isFreeRoundBonus = false;
    this.on('click', () => this.handleClick());
    this.on('touchstart', () => this.handleClick());
    this.initSubscriptions();
    this.handleDisable();
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    this.isFreeRoundBonus = settings.mode === GameMode.FREE_ROUND_BONUS;
    this.handleDisable();
  }

  private initSubscriptions = (): void => {
    eventManager.on(EventTypes.OPEN_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.CLOSE_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_AUTO_SPINS, (isAutoSpins: boolean) => {
      this.isAutoSpins = isAutoSpins;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SLOT_IS_BUSY, (isSlotBusy: boolean) => {
      this.isSlotBusy = isSlotBusy;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_FREESPINS_WIN, (isFreeSpinWin: boolean) => {
      this.isFreeSpinsWin = isFreeSpinWin;
      this.handleDisable();
    });
    eventManager.addListener(EventTypes.CHANGE_STATE, () => {
      this.handleDisable();
    });
  };

  private handleDisable = (): void => {
    this.setDisable(
      !isRegularMode(Logic.the.controller.gameMode) ||
        this.isFreeRoundBonus ||
        this.isAutoSpins ||
        this.isSlotBusy ||
        this.isFreeSpinsWin ||
        setIsPopupOpened() ||
        Logic.the.state.name !== States.IDLE,
    );
  };

  private handleClick = (): void => {
    if (!this.isDisabled && setIsModalOpeningInProgress() === ModalOpeningTypes.NONE) {
      setIsModalOpeningInProgress(ModalOpeningTypes.BET_SETTINGS);

      setIsOpenBetSettingsModal(!setIsOpenBetSettingsModal());
      setIsOpenAutoplayModal(false);
      setIsOpenMenuModal({ isOpen: false, type: 'menu' });
      setIsPopupOpened(false);

      setTimeout(() => setIsModalOpeningInProgress(ModalOpeningTypes.NONE), 100);
    }
  };

  public override handlePosition(): void {
    let x = this.isLeftHandMode
      ? calcPercentage(this.applicationSize.width, 18)
      : calcPercentage(this.applicationSize.width, 82);
    let y = calcPercentage(this.applicationSize.height, 95);
    this.setSize(calcPercentage(this.applicationSize.width, 4));

    if (this.isPortraitMode) {
      this.setSize(calcPercentage(this.underGameContainerSpaceHeight, 20));
      x = this.isLeftHandMode
        ? calcPercentage(this.applicationSize.width, 12.5)
        : calcPercentage(this.applicationSize.width, 87.5);
      y = this.gameContainerBottomPosition + calcPercentage(this.underGameContainerSpaceHeight, 25);
    }

    if (this.isLandscapeMode) {
      this.setSize(calcPercentage(this.applicationSize.height, 8));
      x = this.isLeftHandMode
        ? calcPercentage(this.applicationSize.width, 9)
        : calcPercentage(this.applicationSize.width, 91);
      y = calcPercentage(this.applicationSize.height, 12.5);
    }

    this.x = x;
    this.y = y;
  }
}

export default BetBtn;
