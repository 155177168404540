import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, GameMode, ModalOpeningTypes } from '../../global.d';
import {
  setAutoSpinsLeft,
  setBonusPackAutoPlay,
  setFreePacksBonus,
  setIsAutoSpins,
  setIsModalOpeningInProgress,
  setIsOpenAutoplayModal,
  setIsOpenBetSettingsModal,
  setIsOpenMenuModal,
  setIsPopupOpened,
  setLastRegularWinAmount,
  setMasterPacksBonus,
} from '../../gql/cache';
import { Logic } from '../../logic';
import { States } from '../../logic/config';
import { calcPercentage, isRegularMode } from '../../utils';
import { eventManager } from '../config';

import { ControlButton } from './index';

class AutoplayBtn extends ControlButton {
  private isAutoSpins: boolean;

  private isSlotBusy: boolean;

  private isFreeSpinsWin: boolean;

  constructor() {
    super('auto-play');
    this.interactive = !this.isDisabled;
    this.isAutoSpins = false;
    this.isSlotBusy = false;
    this.isFreeSpinsWin = false;
    this.on('click', () => this.handleClick());
    this.on('touchstart', () => this.handleClick());
    this.initSubscriptions();
    this.handleDisable();
  }

  protected override onModeChange(_settings: { mode: GameMode }): void {
    this.handleDisable();
  }

  private initSubscriptions = (): void => {
    eventManager.on(EventTypes.SET_IS_AUTO_SPINS, (isAutoSpins: boolean) => {
      this.isAutoSpins = isAutoSpins;
      this.handleDisable();
    });
    eventManager.on(EventTypes.OPEN_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.CLOSE_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SLOT_IS_BUSY, (isSlotBusy: boolean) => {
      this.isSlotBusy = isSlotBusy;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_FREESPINS_WIN, (isFreeSpinWin: boolean) => {
      this.isFreeSpinsWin = isFreeSpinWin;
      this.handleDisable();
    });
    eventManager.addListener(EventTypes.CHANGE_STATE, () => {
      this.handleDisable();
    });
    eventManager.addListener(EventTypes.FORCE_STOP_AUTOPLAY, () => {
      if (this.isAutoSpins) {
        this.stopAutoSpins();
      }
    });
    window.addEventListener('message', (event: MessageEvent<{ name: string }>) => {
      if (event.data?.name === 'stopAutoplay') {
        eventManager.emit(EventTypes.FORCE_STOP_AUTOPLAY);
      }
    });
  };

  private handleClick = (): void => {
    if (!this.isDisabled && setIsModalOpeningInProgress() === ModalOpeningTypes.NONE) {
      if (isRegularMode(Logic.the.controller.gameMode)) {
        setIsModalOpeningInProgress(ModalOpeningTypes.AUTOPLAY);

        setIsOpenAutoplayModal(!setIsOpenAutoplayModal());
        setIsOpenMenuModal({ isOpen: false, type: 'menu' });
        setIsOpenBetSettingsModal(false);
        setIsPopupOpened(false);

        setTimeout(() => setIsModalOpeningInProgress(ModalOpeningTypes.NONE), 100);
      } else {
        AudioApi.play({ type: ISongs.SFX_UI_General });
        const totalAutoPlays = setFreePacksBonus().rounds + setMasterPacksBonus().rounds;
        setLastRegularWinAmount(0);
        setAutoSpinsLeft(totalAutoPlays);
        setIsAutoSpins(true);
        setBonusPackAutoPlay(true);
      }
    }
  };

  private handleDisable = (): void => {
    this.setDisable(
      this.isAutoSpins ||
        this.isSlotBusy ||
        this.isFreeSpinsWin ||
        setIsPopupOpened() ||
        Logic.the.state.name !== States.IDLE,
    );
  };

  public override handlePosition(): void {
    let x = this.isLeftHandMode
      ? calcPercentage(this.applicationSize.width, 4)
      : calcPercentage(this.applicationSize.width, 96);
    let y = calcPercentage(this.applicationSize.height, 95);
    this.setSize(calcPercentage(this.applicationSize.width, 4));

    if (this.isPortraitMode) {
      this.setSize(calcPercentage(this.underGameContainerSpaceHeight, 20));
      x = this.isLeftHandMode
        ? calcPercentage(this.applicationSize.width, 12.5)
        : calcPercentage(this.applicationSize.width, 87.5);
      y = this.gameContainerBottomPosition + calcPercentage(this.underGameContainerSpaceHeight, 75);
    }

    if (this.isLandscapeMode) {
      this.setSize(calcPercentage(this.applicationSize.height, 8));
      x = this.isLeftHandMode
        ? calcPercentage(this.applicationSize.width, 9)
        : calcPercentage(this.applicationSize.width, 91);
      y = calcPercentage(this.applicationSize.height, 87.5);
    }

    this.x = x;
    this.y = y;
  }

  private stopAutoSpins(): void {
    setAutoSpinsLeft(0);
    setIsAutoSpins(false);
    this.isAutoSpins = false;
  }
}

export default AutoplayBtn;
