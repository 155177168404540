import type { CardAbilitiesPattern, CardLevel, SlotId } from '../config';
import type { ReelSet } from '../global.d';

import type { BigWinContainer } from './bigWinPresentation/bigWinContainer';
import type CardsContainer from './cards/cardsContainer';
import type { RewardsPopup } from './popups/rewardsPopup/rewardsPopup';
import type WinCountUpMessage from './winAnimations/winCountUpMessage';

export interface ISlotMachine {
  transparent: boolean;
  width: number;
  height: number;
}

export interface IPlayerSettingsMinMax {
  min: number;
  max: number;
}

export interface IPlayerSettings {
  betAmount: IPlayerSettingsMinMax;
  coinValue: {
    EUR: IPlayerSettingsMinMax;
    FUN: IPlayerSettingsMinMax;
  };
}

export interface ISlotData {
  id: string;
  lines: number[][];
  lineSets: LineSet[];
  clientSettings: {
    coinAmounts: {
      default: number[];
      quick: number[];
    };
    coinValues: {
      code: string;
      variants: number[];
    }[];
    features: Features[];
    autoplay: {
      options: number[];
      conditions: {
        stopOnAnyWin: {
          enabled: boolean;
        };
        stopIfFeatureIsWon: {
          enabled: boolean;
        };
        stopIfSingleWinExceeds: {
          enabled: boolean;
          multipliers: number[];
        };
        stopIfBalanceDecreasesBy: {
          enabled: boolean;
          multipliers: number[];
        };
        stopIfBalanceIncreasesBy: {
          enabled: boolean;
          multipliers: number[];
        };
      };
    };
  };
  icons: Icon[];
  previewImage: string;
  reels: ReelSet[];
  settings: {
    betLines: {
      min: number;
      max: number;
    };
    startPosition: number[];
    playerSettings: IPlayerSettings;
  };
}

export interface Features {
  id: string;
  enabled: boolean;
}

export interface LineSet {
  id: string;
  slotId: string;
  lines: number[];
  coinAmountMultiplier: number;
}

export interface IGameContainer {
  cardsContainer: CardsContainer;
  bigWinContainer: BigWinContainer;
  winCountUpMessage: WinCountUpMessage;
  rewardsPopup: RewardsPopup;
}

export type Combos = IconCombo[] | undefined;

export interface Icon {
  combos?: Combo[];
  id: SlotId;
  payoffType: PayoffType;
  type: IconType;
}

export enum IconType {
  EMPTY = 'EMPTY',
  REGULAR = 'REGULAR',
  SCATTER = 'SCATTER',
  WILD = 'WILD',
}

export enum PayoffType {
  LTR = 'LTR',
  RTL = 'RTL',
  ANY = 'ANY',
  ADJACENT = 'ADJACENT',
}
export enum RewardType {
  COINS = 'COINS',
  BONUS = 'BONUS',
}

export enum PackAnimations {
  NormalPackIn = 'pack_normal_in',
  NormalPackOpen = 'pack_open_normal',
  NormalPackOpenMin = 'pack_open_normal_min',
  NormalPackOpenMax = 'pack_open_normal_max',
}
export enum MasterPackAnimations {
  MasterPackIdle = 'pack_master_idle',
  MasterPackIn = 'pack_master_in',
  MasterPackOpen = 'pack_open_master',
  MasterPackOpenMin = 'pack_open_master_min',
  MasterPackOpenMax = 'pack_open_master_max',
}
export enum FreePackAnimations {
  FreePackIdle = 'pack_free_idle',
  FeePackIn = 'pack_free_in',
  FreePackOpen = 'pack_open_free',
  FreePackOpenMin = 'pack_open_free_min',
  FreePackOpenMax = 'pack_open_free_max',
}
export interface Reward {
  bonusId: string | null;
  count: number | null;
  multiplier: number | null;
  type: RewardType;
}
export interface IconCombo {
  pattern: string;
  multiplier: number;
}
export interface Combo {
  rewards: Reward[];
  pattern: string;
  payoffType: string;
  type: string;
}
export interface BuyFeatureConfirmProps {
  totalCost: string;
  coinAmount: number;
}
export interface RewardsPopupProps {
  pattern: CardAbilitiesPattern;
  value: number;
  inId: number;
  outId: number | string;
}
export interface ICardAbility {
  pattern: CardAbilitiesPattern;
  value: number;
}

export interface IInitAbility {
  id: number;
  value: CardLevel | number;
  price: number;
}

export interface FreeRoundsBonusEndProps {
  isExpired: boolean;
}

export type PopupProps = BuyFeatureConfirmProps | RewardsPopupProps | FreeRoundsBonusEndProps | '';
