import { OutlineFilter } from 'pixi-filters';
import { Container, Loader, Sprite, Text } from 'pixi.js';

import i18n from '../../i18next';

import { Tooltips, tooltipTextStyle } from './tooltips.model';

interface TooltipStore {
  items: Tooltip[];
  add: (tooltip: Tooltip) => void;
  hideAll: () => void;
}

export const tooltipsStore: TooltipStore = {
  items: [],
  add: (tooltip: Tooltip) => tooltipsStore.items.push(tooltip),
  hideAll: () => tooltipsStore.items.forEach((tooltip) => tooltip.hide()),
};

export class Tooltip extends Container {
  public override visible = false;
  public override interactive = true;
  public override cursor = 'pointer';

  private tooltipBgWidth = 0;

  static getBg(type: Tooltips): Sprite {
    return new Sprite(Loader.shared.resources[type]?.texture);
  }
  constructor(type: Tooltips, message: string) {
    super();
    const bg = Tooltip.getBg(type);
    this.tooltipBgWidth = bg.width;
    const text = this.getText(message);
    text.filters = [new OutlineFilter(2, 0x000000)];
    tooltipsStore.add(this);
    this.fitTextToBounds(text as Text);
    this.addChild(Tooltip.getBg(type), text);
    text.position.set((this.width - text.width) / 2, ((this.height - text.height) / 2) * 0.6);

    this.on('click', (event: MouseEvent) => {
      this.hide();
      event.stopPropagation();
    });
  }

  public show(): void {
    this.visible = true;
  }

  public hide(): void {
    this.visible = false;
  }

  private getText(message: string): Container {
    return new Text(i18n.t<string>(message), tooltipTextStyle);
  }

  private fitTextToBounds(text: Text): void {
    let fontSize = Number(text.style.fontSize);

    while (text.width > this.tooltipBgWidth - 30) {
      fontSize -= 1;
      text.style = { ...tooltipTextStyle, fontSize };
      text.updateText(true);
    }
  }
}
